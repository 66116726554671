import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { list, preachers, extra } from "./data";
import "lite-youtube-embed/src/lite-yt-embed.css";

function KeyPage() {
   const { key } = useParams();
   const isOk = key == 'all' || key in preachers
   const [values, setValues] = useState(key != 'all' && isOk ? preachers[key].list.map((index) => list[index]) : list);
   const [name_en, setNameEN] = useState(key != 'all' && isOk ? preachers[key].name_en : "ALL VIDEOS");
   const [name_si, setNameSI] = useState(key != 'all' && isOk ? preachers[key].name_si : "සියළු  දේශනා");
   const [alt] = useState(key != 'all' && isOk ? preachers[key].alternative_names : "සියළු  දේශනා");

   // Scroll to top whenever the 'key' changes
   useEffect(() => {
      window.scrollTo(0, 0);
   }, [key]); // The effect runs when the 'key' changes

   useEffect(() => {

      document.title = isOk ? name_si + "-" + name_en : "සොයාගැනීමට නොහැකි විය - Not Found"

      if (isOk && key != 'all') {
         const metaDescription = document.querySelector("meta[name='description']");
         if (metaDescription) {
            metaDescription.setAttribute("content", preachers[key].is_preacher?name_si+" ගේ බණ, ධර්ම දේශනා එකතුව ශ්‍රවණය කිරීමට පිවිසෙන්න." + name_en + " bana, darma deshana collection here.":
               name_si+" පිළිබද බණ, ධර්ම දේශනා, දහම් කරුණු එකතුව ශ්‍රවණය කිරීමට පිවිසෙන්න." + name_en + " bana, darma deshana collection here.")
         }

         const rel = document.querySelector("link[rel='canonical']");
         if (rel) {
            rel.setAttribute("href", "https://www.budubana.com/"+key);
         }

         const ogTitle = document.querySelector("meta[property='og:title']");
         if (ogTitle) {
            ogTitle.setAttribute("content", name_si + "-" + name_en);
         }
         const ogUrl = document.querySelector("meta[property='og:url']");
         if (ogUrl) {
            ogUrl.setAttribute("content", "https://www.budubana.com/"+key);
         }
         const ogDescription = document.querySelector("meta[property='og:description']");
         if(ogDescription){
            ogDescription.setAttribute("content", preachers[key].is_preacher?name_si+" ගේ බණ, ධර්ම දේශනා එකතුව ශ්‍රවණය කිරීමට පිවිසෙන්න." + name_en + " bana, darma deshana collection here.":
               name_si+" පිළිබද බණ, ධර්ම දේශනා, දහම් කරුණු එකතුව ශ්‍රවණය කිරීමට පිවිසෙන්න." + name_en + " bana, darma deshana collection here.")
         }
         const ogImage = document.querySelector("meta[property='og:image']");
         if(ogImage){
            ogImage.setAttribute("content", 'images/og/' + preachers[key].picture.replace('jpg', 'webp'))
         }

         const twitterTitle = document.querySelector("meta[name='twitter:title']");
         if (twitterTitle) {
            twitterTitle.setAttribute("content", name_si + "-" + name_en);
         }
         const twitterDescription = document.querySelector("meta[name='twitter:description']");
         if(twitterDescription){
            twitterDescription.setAttribute("content", preachers[key].is_preacher?name_si+" ගේ බණ, ධර්ම දේශනා එකතුව ශ්‍රවණය කිරීමට පිවිසෙන්න." + name_en + " bana, darma deshana collection here.":
               name_si+" පිළිබද බණ, ධර්ම දේශනා, දහම් කරුණු එකතුව ශ්‍රවණය කිරීමට පිවිසෙන්න." + name_en + " bana, darma deshana collection here.")
         }
         const twitterImage = document.querySelector("meta[name='twitter:image']");
         if(twitterImage){
            twitterImage.setAttribute("content", 'images/og/' + preachers[key].picture.replace('jpg', 'webp'))
         }
      }

   }, [])

   return (

      <div>
         {isOk ?
            <div>
               <h1 style={{ fontFamily: "UN-Abhaya-bold-2019", color: "#fcdf03", margin: '2vw', fontSize: 'min(6vw, 50px)' }}>{name_si ? name_si + " - " + name_en : "Not Found"}</h1>
               {isOk && key != 'all'?
               <h2 className="txt-seo">{preachers[key].is_preacher?name_si+" ගේ බණ, ධර්ම දේශනා එකතුව ශ්‍රවණය කිරීමට පිවිසෙන්න." + name_en + " bana, darma deshana collection here.":
               name_si+" පිළිබද බණ, ධර්ම දේශනා, දහම් කරුණු එකතුව ශ්‍රවණය කිරීමට පිවිසෙන්න." + name_en + " bana, darma deshana collection here."}</h2>: null}
               {values.length > 0 ? (
                  <div style={styles.gridContainer}>
                     {values.map((value, index) => (
                        <div style={styles.card}>
                           {/* {key != 'all' ?<p>{preachers[key].list[index]}</p> : <p>{index}</p>} */}
                           {/* <iframe key={index} style={styles.iframe} 
              src={"https://www.youtube.com/embed/" + value.split("=").reverse()[0] + "?si=Sl0fNUT60lFLIhTu"} 
              title="YouTube video player" 
              frameborder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
              referrerpolicy="strict-origin-when-cross-origin" allowfullscreen>

              </iframe> */}

                           <lite-youtube key={index}
                              videoid={value.split("=").reverse()[0]}
                           ></lite-youtube>
                           <Link class="orig-link" to={value}> See original - Budu Bana</Link>
                        </div>
                     ))}
                  </div>
               ) : (
                  <p>Sorry No results.</p>
               )}
               {key != 'all' ? <div dangerouslySetInnerHTML={{ __html: extra[preachers[key].extra[0]] }} /> : null}
               {key != 'all' ? <h2 style={{ fontFamily: "UN-Abhaya-bold-2019", color: "#fcdf03", margin: '2vw', fontSize: 'min(3vw, 25px)' }}>related : {alt}</h2> : null}
               
            </div>
            : <div>
               <h1  style={{ fontFamily: "UN-Abhaya-bold-2019", color: "#fcdf03", margin: '2vw', fontSize: 'min(6vw, 50px)' }}>සොයාගැනීමට නොහැකි විය - Not Found</h1></div>}
      </div>

   );
}

// CSS-in-JS styles for simplicity
const styles = {
   gridContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(max(300px,20vw), 1fr))', // Minimum column size of 400px
      gap: '20px', // Space between cards
      padding: '20px',
      margin: '0 auto', // Center the grid container
   },
   card: {
      width: '100%', // Card takes up the full column width
      maxWidth: '500px', // Optional: Limit the maximum card width
      minWidth: '300px', // Minimum card width matches iframe
      aspectRatio: '16 / 9', // Maintain a 16:9 aspect ratio for each card
      border: '1px solid #ddd',
      borderRadius: '8px',
      overflow: 'hidden',
      backgroundColor: '#fff',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      transition: 'transform 0.2s',
   },
   iframe: {
      width: '100%', // Fill the card width
      height: '100%', // Automatically adjust height based on aspect ratio
      border: 'none',
      display: 'block',
   },
};


export default KeyPage;
