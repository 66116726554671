import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { preachers } from "./data";

const imageLinks = [
  "images/thero/thumbnails/webp-min/baththaramulle-amadassana-himi.webp",
  "images/thero/thumbnails/webp-min/hadapangoda-niwathapa-himi.webp",
  "images/thero/thumbnails/webp-min/rajagiriye-ariyagnana.webp",
  "images/thero/thumbnails/webp-min/boralle-kovida-himi.webp",
  "images/thero/thumbnails/webp-min/hanwalle-medhankara-himi.webp",
  "images/thero/thumbnails/webp-min/rathupasketiye-vimukthirathana.webp",
  "images/thero/thumbnails/webp-min/galigamuwe-himi.webp",
  "images/thero/thumbnails/webp-min/hasalaka-seelawimala-himi.webp",
  "images/thero/thumbnails/webp-min/waharaka-abayarathana-himi.webp",
  "images/thero/thumbnails/webp-min/gangodawila-soma-himi.webp",
  "images/thero/thumbnails/webp-min/mawarale-baddiya-himi.webp",
  "images/thero/thumbnails/webp-min/walasmulle-abaya-himi.webp",
  "images/thero/thumbnails/webp-min/gothatuwe-rewatha-thero.webp",
  "images/thero/thumbnails/webp-min/meemure-dammawansa-himi.webp",
];


function HomePage() {
  // const [keys, setKeys] = useState(Object.keys(preachers));
  // const [mapperData, setMapperdata] = useState(preachers)

  const keys = Object.keys(preachers);
  const mapperData = preachers



  useEffect(() => {
    // Create the <link> elements and append them to the <head>
    imageLinks.forEach((link) => {
      const linkElement = document.createElement('link');
      linkElement.rel = 'preload';
      linkElement.as = 'image';
      linkElement.href = link;
      document.head.appendChild(linkElement);
    });
  }, []);


  return (
    <div>
      <h1 className="txt-seo">බුදු බණ -
        Budu Bana සියලු මාතෘකා</h1>
      <p className="txt-seo">
        රාජගිරියේ අරියඥාන හිමි, ගලිගමුවේ ඥාණදීප හිමි, බත්තරමුල්ලේ අමාදස්සන හිමි, මීමුරේ ධම්මවංස හිමි, ගංගොඩවිල සෝම හිමි,
        වලස්මුල්ලේ අභය හිමි, රතුපස්කැටියේ විමුක්තිරතන හිමි, හඳපාන්ගොඩ නිවාතාප හිමි, වහරක අභයරතනාලංකාර හිමි, බොරැල්ලේ කෝවිද
        හිමි, හංවැල්ලේ මේධංකර හිමි, පුජ්‍ය ගොතටුවේ රේවත හිමි, මාවරලේ බද්දිය හිමි, තුන් සූත්‍රය, මහ පිරිත, සෙත් පිරිත්,
        සෝවාන් ඵලය, සතර අපාය, බුදු ගුණ, චතුරාර්ය සත්‍ය, පටිච්ච සමුප්පාදය, කඨින පිංකම, අවිද්‍යාව,
        Rajagiriye Ariyagnana Thero, Galigamuwe Gnanadeepa Thero, Baththaramulle Amadassana Thero, Meemure Dhammawansa
        Thero, Gangodawila Soma Himi, Walasmulle Abhaya Thero, Rathupasketiye Vimukthirathana Thero, Hadapangoda Niwathapa
        Thero, Waharaka Abhayarathanalankara Thero, Boralle Kovida Himi, Hanwelle Medhankara Thero, Gothatuwe Rewatha Thero,
        Mawarale Baddiya Thero, Thun Suthraya, Maha Piritha, Seth Pirith, Sowan Palaya, Sathara Apaya, Budu Guna,
        Chathurarya Sathya, Patichcha Samuppadaya, Katina Pinkama, Avidyawa, YOU turn ඔබ හැරෙන්න​ budubanasinha
      </p>
      <h1 style={{ fontFamily: 'UN-Arundathee', color: "#ede609", textAlign: "center", fontSize: "4vw" }}>බුදු බණ - Budu Bana</h1>
      <h1 style={{ fontFamily: 'UN-Gurulugomi', color: "#ede609", textAlign: "center", fontSize: "min(5vw, 50px)" }}>
        "දසබලසේල පභවා නිබ්බානමහා සමුද්ද පරියන්තා අට්ඨංග මග්ගස්සලිලා ජිනවචන තදි චිරං වහභුති"
      </h1>
      <h2 style={{ fontFamily: 'UN-Derana', color: "#ede609", textAlign: "center", fontSize: "min(4vw, 40px)", padding: ('0vw', 'min(5vw, 50px)', '0vw', 'min(5vw, 50px)') }}>
        දසබලයන් වහන්සේ නැමැති ශෛලමය පර්වතයෙන් පැන නැගි අමාමහ නිවන නම්වු මහා සාගරයෙන් අවසන් කොට ඇති අර්ය අශ්ටාංගික මාර්ගය නම්වු සිසිල් දිය දහරින් හෙබි උතුම් ශ්‍රි බුද්ධ වචන ගංගාව ලො සතුන්ගේ සසර දුක නිවාලමින් බොහො කල් ගලා බස්නා සේක්වා !!
      </h2>
      <h1 style={{ fontFamily: 'UN-Arundathee', color: "#ede609", textAlign: "center", fontSize: "4vw" }}>ස්වාමීන් වහන්සේලාගෙන් දේශනා සොයන්න</h1>
      <div style={styles.gridContainer}>
        {keys.map((key) => {
          const item = mapperData[key];

          return (
            item.is_preacher ?
              <Link
                to={`/${key}`}
                key={key}
                style={styles.card}
              >
                <div key={key} style={styles.card}>
                  <img
                    src={'images/thero/thumbnails/webp-min/' + item.picture.replace('jpg', 'webp')}
                    alt={item.name_en}
                    style={styles.image}
                  />
                  <div style={styles.cardContent}>
                    <h2 style={styles.nameSi}>{item.name_si}</h2>
                    <h3 style={styles.nameEn}>{item.name_en}</h3>
                  </div>
                </div>
              </Link> : null
          );
        })}
      </div>

      <h1 style={{ fontFamily: 'UN-Arundathee', color: "#ede609", textAlign: "center", fontSize: "4vw" }}>දහම් කරුණු වලින් දේශනා සොයන්න</h1>
      <div style={styles.gridContainer2}>
        {keys.map((key) => {
          const item = mapperData[key];

          return (
            !item.is_preacher ?
              <Link
                to={`/${key}`}
                key={key}
                style={styles.card}
              >
                <div key={key} style={styles.card}>
                  <div style={styles.cardContent2}>
                    <h2 style={styles.nameSi}>{item.name_si}</h2>
                    <h3 style={styles.nameEn}>{item.name_en}</h3>
                  </div>
                </div>
              </Link> : null
          );
        })}
      </div>
      <h3 className="txt-seo">බුදු බණ -
        Budu Bana සියලු මාතෘකා</h3>
      <h3 className="txt-seo">
        රාජගිරියේ අරියඥාන හිමි, ගලිගමුවේ ඥාණදීප හිමි, බත්තරමුල්ලේ අමාදස්සන හිමි, මීමුරේ ධම්මවංස හිමි, ගංගොඩවිල සෝම හිමි,
        වලස්මුල්ලේ අභය හිමි, රතුපස්කැටියේ විමුක්තිරතන හිමි, හඳපාන්ගොඩ නිවාතාප හිමි, වහරක අභයරතනාලංකාර හිමි, බොරැල්ලේ කෝවිද
        හිමි, හංවැල්ලේ මේධංකර හිමි, පුජ්‍ය ගොතටුවේ රේවත හිමි, මාවරලේ බද්දිය හිමි, තුන් සූත්‍රය, මහ පිරිත, සෙත් පිරිත්,
        සෝවාන් ඵලය, සතර අපාය, බුදු ගුණ, චතුරාර්ය සත්‍ය, පටිච්ච සමුප්පාදය, කඨින පිංකම, අවිද්‍යාව,
        Rajagiriye Ariyagnana Thero, Galigamuwe Gnanadeepa Thero, Baththaramulle Amadassana Thero, Meemure Dhammawansa
        Thero, Gangodawila Soma Himi, Walasmulle Abhaya Thero, Rathupasketiye Vimukthirathana Thero, Hadapangoda Niwathapa
        Thero, Waharaka Abhayarathanalankara Thero, Boralle Kovida Himi, Hanwelle Medhankara Thero, Gothatuwe Rewatha Thero,
        Mawarale Baddiya Thero, Thun Suthraya, Maha Piritha, Seth Pirith, Sowan Palaya, Sathara Apaya, Budu Guna,
        Chathurarya Sathya, Patichcha Samuppadaya, Katina Pinkama, Avidyawa, YOU turn ඔබ හැරෙන්න​ budubanasinha
      </h3>
    </div>
  );
}

// CSS-in-JS styles for simplicity
const styles = {
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(min(200px, 40%), 1fr))',
    gap: '20px',
    padding: '20px',
    maxWidth: '1200px', // Ensure 5 columns max at 200px each
    margin: '0 auto', // Center the grid container
  },
  card: {
    textDecoration: 'none', // Prevent text underline
    color: 'inherit', // Keep text color consistent
    border: '1px solid #ddd',
    borderRadius: '8px',
    overflow: 'hidden',
    backgroundColor: '#fff',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.2s',
    '&:hover': {
      transform: 'scale(1.02)',
      boxShadow: '0 6px 8px rgba(0, 0, 0, 0.2)'
    },
  },
  cardContent: {
    padding: '16px',
    textAlign: 'center',
  },
  image: {
    width: '100%',
    height: '250px',
    objectFit: 'cover',
  },
  nameSi: {
    fontSize: '18px',
    fontWeight: 'bold',
    margin: '8px 0 4px',
  },
  nameEn: {
    fontSize: '14px',
    color: '#666',
    margin: '0 0 12px',
  },
  link: {
    textDecoration: 'none',
    color: '#007BFF',
    fontWeight: 'bold',
    fontSize: '14px',
  },
  gridContainer2: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(min(150px, 20%), 1fr))',
    gap: '20px',
    padding: '20px',
    maxWidth: '1200px', // Ensure 5 columns max at 200px each
    margin: '0 auto', // Center the grid container
  },
  card2: {
    textDecoration: 'none', // Prevent text underline
    color: 'inherit', // Keep text color consistent
    border: '1px solid #ddd',
    borderRadius: '8px',
    overflow: 'hidden',
    backgroundColor: '#fff',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.2s',
    '&:hover': {
      transform: 'scale(1.02)',
      boxShadow: '0 6px 8px rgba(0, 0, 0, 0.2)'
    },
  },
  cardContent2: {
    padding: '5px',
    textAlign: 'center',
  },
};

export default HomePage;
