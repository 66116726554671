const preachers = {
    "rajagiriye-ariyagnana": {
        "extra": [], "is_preacher": true,
        "name_en": "Rajagiriye Ariyagnana Thero",
        "name_si": "රාජගිරියේ අරියඥාන හිමි",
        "alternative_names": "රාජගිරියේ හිමි, Ariyanana himi, Ariyagana himi",
        "picture": "rajagiriye-ariyagnana.jpg",
        "list": [
            84,
            85,
            86,
            87,
            88,
            89,
            90,
            91,
            92,
            93,
            94,
            95,
            96,
            97,
            98,
            99,
            100,
            101,
            102,
            103,
            104,
            105,
            106,
            107,
            108,
            109,
            110,
            111,
            112,
            113,
            114,
            115,
            116,
            117,
            118,
            119,
            120,
            121,
            122,
            123,
            124,
            125
        ]
    },
    "galigamuwe-gnanadeepa": {
        "extra": [], "is_preacher": true,
        "name_en": "Galigamuwe Gnanadeepa Thero",
        "name_si": "ගලිගමුවේ ඥාණදීප හිමි",
        "alternative_names": "ගලිගමුවේ හිමි, Galigamuwe Himi",
        "picture": "galigamuwe-himi.jpg",
        "list": [
            0,
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
            11,
            12
        ]
    },
    "baththaramulle-amadassana": {
        "extra": [], "is_preacher": true,
        "name_en": "Baththaramulle Amadassana Thero",
        "name_si": "බත්තරමුල්ලේ අමාදස්සන හිමි",
        "alternative_names": "බත්තරමුල්ලේ හිමි, Amadassana Himi",
        "picture": "baththaramulle-amadassana-himi.jpg",
        "list": [
            13,
            14,
            15,
            16,
            17,
            18,
            19,
            20,
            21,
            22,
            23,
            24,
            25,
            26,
            27,
            28,
            29,
            30,
            31,
            32
        ]
    },
    "meemure-dhammawansa": {
        "extra": [], "is_preacher": true,
        "name_en": "Meemure Dhammawansa Thero",
        "name_si": "මීමුරේ ධම්මවංස හිමි",
        "alternative_names": "මීමුරේ ධම්මවංශ හිමි, Meemure Dhammawansha Thero",
        "picture": "meemure-dammawansa-himi.jpg",
        "list": [
            33,
            34,
            35,
            36,
            37,
            38,
            39,
            40,
            41,
            42,
            43,
            44
        ]
    },
    "gangodawila-soma-himi": {
        "extra": [], "is_preacher": true,
        "name_en": "Gangodawila Soma Himi",
        "name_si": "ගංගොඩවිල සෝම හිමි",
        "alternative_names": "ගංගොඩවිල හිමි, Gangodavila Soma Himi",
        "picture": "gangodawila-soma-himi.jpg",
        "list": [
            45,
            46,
            47
        ]
    },
    "walasmulle-abaya-himi": {
        "extra": [], "is_preacher": true,
        "name_en": "Walasmulle Abhaya Thero",
        "name_si": "වලස්මුල්ලේ අභය හිමි",
        "alternative_names": "වලස්මුල්ලේ හිමි, Walasmulle Abaya Thero",
        "picture": "walasmulle-abaya-himi.jpg",
        "list": [
            48,
            49,
            50,
            51,
            52,
            53,
            54,
            55,
            56,
            57,
            58,
            59,
            60,
            61,
            62,
            63,
            64,
            65,
            66,
            67,
            68,
            69,
            70,
            71,
            72,
            73,
            74,
            75,
            76,
            77,
            78,
            79,
            80,
            81,
            82,
            83
        ]
    },
    "rathupasketiye-vimukthirathana": {
        "extra": [], "is_preacher": true,
        "name_en": "Rathupasketiye Vimukthirathana Thero",
        "name_si": "රතුපස්කැටියේ විමුක්තිරතන හිමි",
        "alternative_names": "Rathupasketiye Wimukthirathana Thero, Wimuththirathana Thero",
        "picture": "rathupasketiye-vimukthirathana.jpg",
        "list": [
            126,
            127,
            128,
            129,
            130,
            131,
            132,
            133,
            134,
            135,
            136,
            137,
            138
        ]
    },
    "hadapangoda-niwathapa": {
        "extra": [], "is_preacher": true,
        "name_en": "Hadapangoda Niwathapa Thero",
        "name_si": "හඳපාන්ගොඩ නිවාතාප හිමි",
        "alternative_names": "Hadapangoda Nivathapa Thero",
        "picture": "hadapangoda-niwathapa-himi.jpg",
        "list": [
            139,
            140,
            141,
            142,
            143,
            144,
            145,
            146,
            147,
            148,
            149,
            150,
            151,
            152,
            153,
            154,
            155,
            156,
            157,
            158,
            159,
            160,
            161,
            162,
            163,
            164,
            165,
            166,
            167,
            168,
            169,
            170,
            171,
            172,
            173,
            174,
            175,
            176,
            177,
            178,
            179,
            180,
            181,
            182,
            183,
            184,
            185,
            186,
            187,
            188,
            189,
            190,
            191,
            192,
            193,
            194,
            195,
            196,
            197,
            198,
            199
        ]
    },
    "waharaka-abayarathananalankara": {
        "extra": [], "is_preacher": true,
        "name_en": "Waharaka Abhayarathanalankara Thero",
        "name_si": "වහරක අභයරතනාලංකාර හිමි",
        "alternative_names": "Waharaka Abayarathanalankara Thero, Waharaka Abhaya Thero",
        "picture": "waharaka-abayarathana-himi.jpg",
        "list": [
            200,
            201,
            202,
            203,
            204,
            205,
            206,
            207,
            208,
            209,
            210,
            211,
            212,
            213,
            214,
            215,
            216,
            217,
            218,
            219,
            220,
            221,
            222,
            223,
            224,
            225,
            226,
            227,
            228,
            229,
            230,
            231,
            232,
            233,
            234,
            235,
            236,
            237,
            238,
            239,
            240,
            241,
            242,
            243,
            244,
            245,
            246,
            247,
            248,
            249,
            250,
            251,
            252,
            253,
            254,
            255,
            256,
            257,
            258,
            259,
            260,
            261,
            262,
            263,
            264
        ]
    },
    "boralle-kovida": {
        "extra": [], "is_preacher": true,
        "name_en": "Boralle Kovida Himi",
        "name_si": "බොරැල්ලේ කෝවිද හිමි",
        "alternative_names": "Boralle Kowida Himi, kowidha himi",
        "picture": "boralle-kovida-himi.jpg",
        "list": [
            265,
            266
        ]
    },
    "hanwalle-medhankara": {
        "extra": [], "is_preacher": true,
        "name_en": "Hanwelle Medhankara Thero",
        "name_si": "හංවැල්ලේ මේධංකර හිමි",
        "alternative_names": "Hanwelle Medankara Thero",
        "picture": "hanwalle-medhankara-himi.jpg",
        "list": [
            267,
            268,
            269,
            270,
            271,
            272,
            273,
            274,
            275,
            276,
            277,
            278,
            279,
            280
        ]
    },
    "gothatuwe-rewatha": {
        "extra": [], "is_preacher": true,
        "name_en": "Gothatuwe Rewatha Thero",
        "name_si": "පුජ්‍ය ගොතටුවේ රේවත හිමි",
        "alternative_names": "Gothatuwe Revatha Thero",
        "picture": "gothatuwe-rewatha-thero.jpg",
        "list": [
            281,
            282,
            283,
            284,
            285,
            286,
            287,
            288,
            289,
            290,
            291,
            292,
            293,
            294,
            295,
            296,
            297,
            298,
            299,
            300
        ]
    },
    "mawarale-baddiya": {
        "extra": [], "is_preacher": true,
        "name_en": "Mawarale Baddiya Thero",
        "name_si": "මාවරලේ බද්දිය හිමි",
        "alternative_names": "භද්දිය හිමි, bhaddiya himi",
        "picture": "mawarale-baddiya-himi.jpg",
        "list": [
            301,
            302,
            303,
            304,
            305,
            306,
            307,
            308,
            309,
            310,
            311,
            312,
            313,
            314,
            315,
            316,
            317
        ]
    },
    "thun-suthraya": {
        "extra": ["thun-suthraya"], "is_preacher": false,
        "name_en": "Thun Suthraya",
        "name_si": "තුන් සූත්‍රය",
        "alternative_names": "තුන් සූත්‍රය, Thun Suthraya, Pirith, මහ පිරිත, Maha Piritha",
        "picture": "thun-suthraya.jpg",
        "list": [
            318
        ]
    },
    "maha-piritha": {
        "extra": ["thun-suthraya"], "is_preacher": false,
        "name_en": "Maha Piritha",
        "name_si": "මහ පිරිත",
        "alternative_names": "තුන් සූත්‍රය, Thun Suthraya, Pirith, මහ පිරිත, Maha Piritha",
        "picture": "thun-suthraya.jpg",
        "list": [
            318
        ]
    },
    "seth-pirith": {
        "extra": ["thun-suthraya"], "is_preacher": false,
        "name_en": "Seth Pirith",
        "name_si": "සෙත් පිරිත්",
        "alternative_names": "තුන් සූත්‍රය, Thun Suthraya, Pirith, මහ පිරිත, Maha Piritha",
        "picture": "seth-pirith.jpg",
        "list": [
            318, 323
        ]
    },
    "sowan": {
        "extra": [], "is_preacher": false,
        "name_en": "Sowan Palaya",
        "name_si": "සෝවාන් ඵලය",
        "alternative_names": "සෝතාපන්න, sothapanna palaya, sowan Phalaya, maga pala",
        "picture": "sowan-palaya.jpg",
        "list": [102, 105, 1, 5, 11, 18, 23, 24, 36, 37, 43, 44, 48,
            49,
            53,
            56,
            58,
            59,
            61,
            62,
            63,
            64,
            65,
            66,
            68,
            69,
            70,
            71,
            72,
            73,
            74,
            75,
            76,
            77,
            78,
            129, 132, 134, 135, 136,
            145, 146, 147, 152, 159, 169, 173, 178, 184, 196,
            204, 205, 210, 225, 232, 257, 260,
            267, 271, 274, 277,
            281, 283, 285, 287, 290, 292, 295, 297, 298,
            301, 310
        ]
    },
    "sathara-apaya": {
        "extra": [], "is_preacher": false,
        "name_en": "Sathara Apaya",
        "name_si": "සතර අපාය",
        "alternative_names": "ප්‍රේත, නිරය, තිරිසන්, අසුර, නරකය, apaaya, pretha, niraya, asura nikaya, narakaya",
        "picture": "sathara-apaya.jpg",
        "list": [
            124, 33, 90, 96, 99, 114, 117, 2, 8, 12, 137, 142, 143, 151, 153, 154, 156, 163

        ]
    },
    "budu-guna": {
        "extra": [], "is_preacher": false,
        "name_en": "Budu Guna",
        "name_si": "බුදු ගුණ",
        "alternative_names": "budhu guna, bhagawa, arahan, samma sambudhdha, vijjacharana sampanna, sugatha, lokawidu,anuththaro purusadamma sarathi, saththa dewamanussanan, buddo",
        "picture": "budu-guna.jpg",
        "list": [
            125, 15, 27, 141, 150, 182, 203, 237, 244, 319
        ]
    }
    ,
    "chathurarya-sathya": {
        "extra": [], "is_preacher": false,
        "name_en": "Chathurarya Sathya",
        "name_si": "චතුරාර්ය සත්‍ය",
        "alternative_names": "dukka arya sathya, dukka samudaya arya sathya, dukka niroda arya sathya, dukka niroda gamini patipada arya sathya",
        "picture": "chathurarya-sathya.jpg",
        "list": [
            36, 38, 39, 43, 48, 129, 132, 159, 191, 204, 281, 284, 299
        ]
    },
    "patichcha-samuppadaya": {
        "extra": [], "is_preacher": false,
        "name_en": "Patichcha Samuppadaya",
        "name_si": "පටිච්ච සමුප්පාදය",
        "alternative_names": "avidya, sankara, wignana, nama roopa, salayathana, passa, wedana, thanha, updana, bhawa, jathi, jara marana",
        "picture": "patichcha-samuppadaya.jpg",
        "list": [36, 38, 39, 43, 44, 60, 128, 129, 132, 191, 231, 252, 284, 285, 298

        ]
    },
    "katina-pinkama": {
        "extra": [], "is_preacher": false,
        "name_en": "Katina Pinkama",
        "name_si": "කඨින පිංකම",
        "alternative_names": "කටින පිංකම, කඨින පුජාව, දුස්සය, පඩු පෙවීම්, චීවර පුජාව, කඨිනානිසංස, වස් තුන් මස, වස්සාන කාලය, katinanisansa, wassana kalaya, padu peweema",
        "picture": "katina-pinkama.jpg",
        "list": [
            265, 320, 321, 322
        ]
    },
    "avidyawa": {
        "extra": [], "is_preacher": false,
        "name_en": "Avidyawa",
        "name_si": "අවිද්‍යාව",
        "alternative_names": "අවිජ්ජා, නොදන්නා කම, මෝහය, මුලාව, awidyawa, avijja, mohaya, mulawa",
        "picture": "avidyawa.jpg",
        "list": [
            0, 1, 11, 18, 28, 35, 38, 105, 128, 130, 132, 133, 145, 159, 181, 202, 300, 298, 299
        ]
    },
    // "sakkaya-dittiya": {
    //     "extra":[],"is_preacher": false,
    //     "name_en": "Sakkaya Dittiya",
    //     "name_si": "සක්කාය දිට්ටිය",
    //     "alternative_names": "සත්කාය දෘෂ්ටිය, මම මගේ, මමය මාගේය, sathkaya drushtiya, mama mage, mamaya, mageya",
    //     "list": [
    //     ]
    // },
    // "amanushya-dosha": {
    //     "extra":[],"is_preacher": false,
    //     "name_en": "Amanushya Dosha",
    //     "name_si": "සක්කාය දිට්ටිය",
    //     "alternative_names": "",
    //     "list": [
    //     ]
    // },
    // "bana-katha": {
    //     "extra":[],"is_preacher": false,
    //     "name_en": "Bana Katha",
    //     "name_si": "",
    //     "alternative_names": "",
    //     "list": [
    //     ]
    // },
    // "vipassana-bhawana": {
    //     "extra":[],"is_preacher": false,
    //     "name_en": "Vipassana Bhawana",
    //     "name_si": "",
    //     "alternative_names": "",
    //     "list": [
    //     ]
    // },
    // "samatha-bhawana": {
    //     "extra":[],"is_preacher": false,
    //     "name_en": "Samatha Bhawana",
    //     "name_si": "",
    //     "alternative_names": "",
    //     "list": [
    //     ]
    // },
    // "buddanussathiya": {
    //     "extra":[],"is_preacher": false,
    //     "name_en": "Buddanussathiya",
    //     "name_si": "",
    //     "alternative_names": "",
    //     "list": [
    //     ]
    // }
    // , "karmaya": {
    //     "extra":[],"is_preacher": false,
    //     "name_en": "Karmaya",
    //     "name_si": "",
    //     "alternative_names": "",
    //     "list": [
    //     ]
    // },
    // "panchaskandhaya": {
    //     "extra":[],"is_preacher": false,
    //     "name_en": "Panchaskandhaya",
    //     "name_si": "",
    //     "alternative_names": "",
    //     "list": [
    //     ]
    // },
    // "Panchupadanaskandhaya": {
    //     "extra":[],"is_preacher": false,
    //     "name_en": "Panchupadanaskandhaya",
    //     "name_si": "",
    //     "alternative_names": "",
    //     "list": [
    //     ]
    // },
    // "anichcha": {
    //     "extra":[],"is_preacher": false,
    //     "name_en": "Anichcha",
    //     "name_si": "",
    //     "alternative_names": "",
    //     "list": [
    //     ]
    // },
    // "dukka": {
    //     "extra":[],"is_preacher": false,
    //     "name_en": "Dukka",
    //     "name_si": "",
    //     "alternative_names": "",
    //     "list": [
    //     ]
    // },
    // "anaththa": {
    //     "extra":[],"is_preacher": false,
    //     "name_en": "Anaththa",
    //     "name_si": "",
    //     "alternative_names": "",
    //     "list": [
    //     ]
    // },
    // "sansaraya": {
    //     "extra":[],"is_preacher": false,
    //     "name_en": "Sansaraya",
    //     "name_si": "",
    //     "alternative_names": "",
    //     "list": [
    //     ]
    // },
    // "budda-pujawa": {
    //     "extra":[],"is_preacher": false,
    //     "name_en": "Budda Pujawa",
    //     "name_si": "",
    //     "alternative_names": "",
    //     "list": [
    //     ]
    // },
    // "pin-anumodan-kirima": {
    //     "extra":[],"is_preacher": false,
    //     "name_en": "Pin Anumodan Kirima",
    //     "name_si": "",
    //     "alternative_names": "",
    //     "list": [
    //     ]
    // },
    // "seelaya": {
    //     "extra":[],"is_preacher": false,
    //     "name_en": "Seelaya",
    //     "name_si": "",
    //     "alternative_names": "",
    //     "list": [
    //     ]
    // },
    // "ashwada-adeenawa-nissarana": {
    //     "extra":[],"is_preacher": false,
    //     "name_en": "Ashwada Adeenawa Nissarana",
    //     "name_si": "",
    //     "alternative_names": "",
    //     "list": [
    //     ]
    // },
    // "kamaya": {
    //     "extra":[],"is_preacher": false,
    //     "name_en": "Kamaya",
    //     "name_si": "",
    //     "alternative_names": "",
    //     "list": [
    //     ]
    // },
    // "maithree-bhawanawa": {
    //     "extra":[],"is_preacher": false,
    //     "name_en": "Maithree Bhawanawa",
    //     "name_si": "",
    //     "alternative_names": "",
    //     "list": [
    //     ]
    // },
    // "anapanasathiya": {
    //     "extra":[],"is_preacher": false,
    //     "name_en": "Maithree Bhawanawa",
    //     "name_si": "",
    //     "alternative_names": "",
    //     "list": [
    //     ]
    // }
}

const list = [
    "https://www.youtube.com/watch?v=CKtJSOJZoK8",
    "https://www.youtube.com/watch?v=s0hcWryFDx4",
    "https://www.youtube.com/watch?v=9xVhQQqccqM",
    "https://www.youtube.com/watch?v=bwWfAwHfim8",
    "https://www.youtube.com/watch?v=-c9hszmtAiE",
    "https://www.youtube.com/watch?v=IJUu6r10D6E",
    "https://www.youtube.com/watch?v=_gP1PSkC7nk",
    "https://www.youtube.com/watch?v=pyGQ-VhTmgY",
    "https://www.youtube.com/watch?v=1fPwz7vB_34",
    "https://www.youtube.com/watch?v=NsAVGNAD01w",
    "https://www.youtube.com/watch?v=STuln4u8La8",
    "https://www.youtube.com/watch?v=Kg3IMc-KBfY",
    "https://www.youtube.com/watch?v=B2ZiYTW3JI8",
    "https://www.youtube.com/watch?v=IwamO9W_QdM",
    "https://www.youtube.com/watch?v=yCs4UMR6dac",
    "https://www.youtube.com/watch?v=qnVZfiwJU1U",
    "https://www.youtube.com/watch?v=fH9XlPFidZ8",
    "https://www.youtube.com/watch?v=GNjixGhKFjo",
    "https://www.youtube.com/watch?v=Pj8cxqotYhI",
    "https://www.youtube.com/watch?v=r-PABgsHNsI",
    "https://www.youtube.com/watch?v=MaW6sjvyxuk",
    "https://www.youtube.com/watch?v=dpEdf2Mdf80",
    "https://www.youtube.com/watch?v=-u6PH01Oo_o",
    "https://www.youtube.com/watch?v=WTcbYLWJJ4g",
    "https://www.youtube.com/watch?v=xXAcF3IdvzY",
    "https://www.youtube.com/watch?v=XcmtF2lRPSE",
    "https://www.youtube.com/watch?v=tk1yKsKwKWY",
    "https://www.youtube.com/watch?v=EGbzY34cqwA",
    "https://www.youtube.com/watch?v=BfQcdN_TM4w",
    "https://www.youtube.com/watch?v=MOD9PfsDERE",
    "https://www.youtube.com/watch?v=s-IEr_VyowU",
    "https://www.youtube.com/watch?v=MeubjNup-WA",
    "https://www.youtube.com/watch?v=U8dJMOelzwQ",
    "https://www.youtube.com/watch?v=eeAqN98OIjs",
    "https://www.youtube.com/watch?v=peFggUEjaVo",
    "https://www.youtube.com/watch?v=Qp1tIgh9epg",
    "https://www.youtube.com/watch?v=prm7o3tHNfA",
    "https://www.youtube.com/watch?v=YJylVOaloPc",
    "https://www.youtube.com/watch?v=j-sEJwcQI7c",
    "https://www.youtube.com/watch?v=loBtKQ6-tSw",
    "https://www.youtube.com/watch?v=UO-JaRh7Gf8",
    "https://www.youtube.com/watch?v=FdKtfKGpFTI",
    "https://www.youtube.com/watch?v=5G8j1tZj2Jw",
    "https://www.youtube.com/watch?v=H3StXG-XD-0",
    "https://www.youtube.com/watch?v=gTXVbOD-IyY",
    "https://www.youtube.com/watch?v=8_0fw5Bh1DU",
    "https://www.youtube.com/watch?v=UN1OXWn3vbo",
    "https://www.youtube.com/watch?v=FzIML-H3Sf4",
    "https://www.youtube.com/watch?v=o6fDV2yFwRY",
    "https://www.youtube.com/watch?v=CSJ7n1MQgdU",
    "https://www.youtube.com/watch?v=_YeB8ChSLTM",
    "https://www.youtube.com/watch?v=gNMfFGL12OY",
    "https://www.youtube.com/watch?v=rV1-F5JeB08",
    "https://www.youtube.com/watch?v=jawzQj4hm08",
    "https://www.youtube.com/watch?v=L7a6JbJ581I",
    "https://www.youtube.com/watch?v=kIjnL1qnEP4",
    "https://www.youtube.com/watch?v=_V4dBZm7W2M",
    "https://www.youtube.com/watch?v=s0PF6QlUbSI",
    "https://www.youtube.com/watch?v=-CXwW-sCdi4",
    "https://www.youtube.com/watch?v=7peuaGaiyTs",
    "https://www.youtube.com/watch?v=-90SfHeUJiU",
    "https://www.youtube.com/watch?v=-skGAzkFkWk",
    "https://www.youtube.com/watch?v=uE_-3d-wvtU",
    "https://www.youtube.com/watch?v=4vY3Bb_8f2U",
    "https://www.youtube.com/watch?v=0-DuHvZabHw",
    "https://www.youtube.com/watch?v=M5nqNoqci7Q",
    "https://www.youtube.com/watch?v=uZnrFKxsZOE",
    "https://www.youtube.com/watch?v=CSmyXmRXyss",
    "https://www.youtube.com/watch?v=GGOdXZ3mtoU",
    "https://www.youtube.com/watch?v=k1GkRjdqlrg",
    "https://www.youtube.com/watch?v=Ak89wzzhZIE",
    "https://www.youtube.com/watch?v=NxWcGIZusc8",
    "https://www.youtube.com/watch?v=Is75zP1TFxw",
    "https://www.youtube.com/watch?v=4WTyeS3v3Vk",
    "https://www.youtube.com/watch?v=4ApkLz74EUo",
    "https://www.youtube.com/watch?v=P11u0aTI7NA",
    "https://www.youtube.com/watch?v=Ig0rFV6QV5s",
    "https://www.youtube.com/watch?v=Tm6PjlU2j-8",
    "https://www.youtube.com/watch?v=wAJVsiKFn5M",
    "https://www.youtube.com/watch?v=Toj3tqSRlHg",
    "https://www.youtube.com/watch?v=AMfuFy8gUaI",
    "https://www.youtube.com/watch?v=2wBzTpxfQDc",
    "https://www.youtube.com/watch?v=xZIYv4xHtDg",
    "https://www.youtube.com/watch?v=1owe_VA5Krw",
    "https://www.youtube.com/watch?v=j-19r8B75ks",
    "https://www.youtube.com/watch?v=QqtMN5zYaGM",
    "https://www.youtube.com/watch?v=GDRBHacHLUE",
    "https://www.youtube.com/watch?v=tM7g_YZ1h_o",
    "https://www.youtube.com/watch?v=Nt0It4tW2Gw",
    "https://www.youtube.com/watch?v=WWAKFMRMT84",
    "https://www.youtube.com/watch?v=tShahEeqkW8",
    "https://www.youtube.com/watch?v=l1xM8AdiLZ0",
    "https://www.youtube.com/watch?v=zL4w1Zl5XBM",
    "https://www.youtube.com/watch?v=XjZEs6u2Ano",
    "https://www.youtube.com/watch?v=XyrIzMLJcHo",
    "https://www.youtube.com/watch?v=TrHRjitTTOQ",
    "https://www.youtube.com/watch?v=-WTQYVDnx5o",
    "https://www.youtube.com/watch?v=kCHVWDV62gE",
    "https://www.youtube.com/watch?v=mVex-IQMoHw",
    "https://www.youtube.com/watch?v=95iNAKRr6-A",
    "https://www.youtube.com/watch?v=PwgjQwC12Y4",
    "https://www.youtube.com/watch?v=Y4PEGj76RVg",
    "https://www.youtube.com/watch?v=WQGp0SuErJ4",
    "https://www.youtube.com/watch?v=aSMlIWDbrZU",
    "https://www.youtube.com/watch?v=FgKtN4J7Hwo",
    "https://www.youtube.com/watch?v=9mwDejAKSYs",
    "https://www.youtube.com/watch?v=IjQMh0ry1N0",
    "https://www.youtube.com/watch?v=QDzaeVBavyQ",
    "https://www.youtube.com/watch?v=6DzeTtkt27I",
    "https://www.youtube.com/watch?v=wIfPeVjwJCQ",
    "https://www.youtube.com/watch?v=X5ziGSkDwOw",
    "https://www.youtube.com/watch?v=ObjWsUfUNTE",
    "https://www.youtube.com/watch?v=xvi3tVo-fvU",
    "https://www.youtube.com/watch?v=2bf8aroCYUg",
    "https://www.youtube.com/watch?v=CWbJEk-GhVk",
    "https://www.youtube.com/watch?v=radBAg_jr1U",
    "https://www.youtube.com/watch?v=p2cXa4UTAP4",
    "https://www.youtube.com/watch?v=dpTFtq_xqeA",
    "https://www.youtube.com/watch?v=xDYALMtL_PA",
    "https://www.youtube.com/watch?v=MH-QENtiDzM",
    "https://www.youtube.com/watch?v=i51fjF8oUyI",
    "https://www.youtube.com/watch?v=nB6cZwYt-ww",
    "https://www.youtube.com/watch?v=XdaAcJZuQpU",
    "https://www.youtube.com/watch?v=kjV6QqH1v-g",
    "https://www.youtube.com/watch?v=seUFMqL7Fp0",
    "https://www.youtube.com/watch?v=SdSqkLUYsZg",
    "https://www.youtube.com/watch?v=xv_RQ2I9N04",
    "https://www.youtube.com/watch?v=zmuDEGHLsMA",
    "https://www.youtube.com/watch?v=rzzMNLO0OZg",
    "https://www.youtube.com/watch?v=CXi-m-gxpaw",
    "https://www.youtube.com/watch?v=Mw3y0nDoQhY",
    "https://www.youtube.com/watch?v=_6T6Pjbcecs",
    "https://www.youtube.com/watch?v=2Lz1D9GZbSI",
    "https://www.youtube.com/watch?v=xeZXl_veis4",
    "https://www.youtube.com/watch?v=ckTm-le_6Eo",
    "https://www.youtube.com/watch?v=vv0dvIa05Ok",
    "https://www.youtube.com/watch?v=tDABFRfV5PE",
    "https://www.youtube.com/watch?v=zxlusgURf88",
    "https://www.youtube.com/watch?v=IDyqZLIRe4A",
    "https://www.youtube.com/watch?v=58PrBHJ5HKo",
    "https://www.youtube.com/watch?v=yTwpRyedMiI",
    "https://www.youtube.com/watch?v=fRpTUArNH0E",
    "https://www.youtube.com/watch?v=S2Q1C1fOUrY",
    "https://www.youtube.com/watch?v=4T2KJusmO94",
    "https://www.youtube.com/watch?v=ekN6BgG5TLY",
    "https://www.youtube.com/watch?v=nNuV-W_llMk",
    "https://www.youtube.com/watch?v=Og_kNs7JEn0",
    "https://www.youtube.com/watch?v=WZRbauQTwVU",
    "https://www.youtube.com/watch?v=J979dxZkgzU",
    "https://www.youtube.com/watch?v=vgUz7XGV8To",
    "https://www.youtube.com/watch?v=3pZPa4-0TIk",
    "https://www.youtube.com/watch?v=3418jWGuH4c",
    "https://www.youtube.com/watch?v=ZMC7GrFqL-Y",
    "https://www.youtube.com/watch?v=KPM5mG7xaDE",
    "https://www.youtube.com/watch?v=x8HPdgBVVSM",
    "https://www.youtube.com/watch?v=uCAgB4OPBPo",
    "https://www.youtube.com/watch?v=Q731tR3ABj8",
    "https://www.youtube.com/watch?v=fQwLY0arxWY",
    "https://www.youtube.com/watch?v=J5Tx2Zq0eLE",
    "https://www.youtube.com/watch?v=qTOR6m2TvMs",
    "https://www.youtube.com/watch?v=sqZ01SOo_E0",
    "https://www.youtube.com/watch?v=mt8i_IkAnSs",
    "https://www.youtube.com/watch?v=KHEYRnHYNeI",
    "https://www.youtube.com/watch?v=PqWU6dQKGAY",
    "https://www.youtube.com/watch?v=OQTVeoleo_A",
    "https://www.youtube.com/watch?v=AlWo35WxQ2U",
    "https://www.youtube.com/watch?v=geeAVFQ4YOQ",
    "https://www.youtube.com/watch?v=wn2mU0LyFO4",
    "https://www.youtube.com/watch?v=26K4mqTrn8I",
    "https://www.youtube.com/watch?v=aoa1ky9wQYQ",
    "https://www.youtube.com/watch?v=YI4Hs08i5WE",
    "https://www.youtube.com/watch?v=JRORuc_a3e8",
    "https://www.youtube.com/watch?v=tGUXEhu9ppg",
    "https://www.youtube.com/watch?v=Qh2pqrFA6tM",
    "https://www.youtube.com/watch?v=tXxfRSIPkUM",
    "https://www.youtube.com/watch?v=sKR2AoZ3hDc",
    "https://www.youtube.com/watch?v=ZTJRhsnmTtQ",
    "https://www.youtube.com/watch?v=Wx5mzbS0vcQ",
    "https://www.youtube.com/watch?v=lcT16JxEaZs",
    "https://www.youtube.com/watch?v=rw1aXr9xQTg",
    "https://www.youtube.com/watch?v=qTUa23RMutA",
    "https://www.youtube.com/watch?v=y00XzyB4ZYE",
    "https://www.youtube.com/watch?v=ZxM5NxeGyE4",
    "https://www.youtube.com/watch?v=kQoxsq4dHu0",
    "https://www.youtube.com/watch?v=p2JhLVJ4BTQ",
    "https://www.youtube.com/watch?v=XQYQaIk87ag",
    "https://www.youtube.com/watch?v=syw6WnWraVk",
    "https://www.youtube.com/watch?v=PLzy5tDA8XQ",
    "https://www.youtube.com/watch?v=sijKN_QIF3w",
    "https://www.youtube.com/watch?v=t5x_42KQ3JM",
    "https://www.youtube.com/watch?v=UYvKurB1k_k",
    "https://www.youtube.com/watch?v=TSrIGcret5g",
    "https://www.youtube.com/watch?v=uaVfk7HMgCo",
    "https://www.youtube.com/watch?v=mCZhBRr4DoQ",
    "https://www.youtube.com/watch?v=f_cTDQ3teoE",
    "https://www.youtube.com/watch?v=UqXU4xUYfuY",
    "https://www.youtube.com/watch?v=tYZv5yoWcSw",
    "https://www.youtube.com/watch?v=Prkp2iykRWM",
    "https://www.youtube.com/watch?v=pVLc7_82sB4",
    "https://www.youtube.com/watch?v=KadTOVxNXWw",
    "https://www.youtube.com/watch?v=c7kqmjE7wDY",
    "https://www.youtube.com/watch?v=A4T2Tm2EuQE",
    "https://www.youtube.com/watch?v=IsWl5YMD2vQ",
    "https://www.youtube.com/watch?v=sSyXckCCMAo",
    "https://www.youtube.com/watch?v=JNr5Mdzoo28",
    "https://www.youtube.com/watch?v=mEVLA7WeGoU",
    "https://www.youtube.com/watch?v=zQirT7Af2lE",
    "https://www.youtube.com/watch?v=Dlpf1AhniDM",
    "https://www.youtube.com/watch?v=ubytFpfEIxY",
    "https://www.youtube.com/watch?v=YL1Qr-QGkJ0",
    "https://www.youtube.com/watch?v=-LxdQEWP--w",
    "https://www.youtube.com/watch?v=3eaeytjv6CA",
    "https://www.youtube.com/watch?v=EPCOIRiLBfQ",
    "https://www.youtube.com/watch?v=eGCAv79j58k",
    "https://www.youtube.com/watch?v=AZgfEfP2Qjc",
    "https://www.youtube.com/watch?v=HaX3TeDKozc",
    "https://www.youtube.com/watch?v=N9PaCUov9CU",
    "https://www.youtube.com/watch?v=A3m9XSLRUoU",
    "https://www.youtube.com/watch?v=5G4-sRWY_yA",
    "https://www.youtube.com/watch?v=YiKq7K6gki8",
    "https://www.youtube.com/watch?v=uWaDCt0hq0k",
    "https://www.youtube.com/watch?v=4b8-TYojb-8",
    "https://www.youtube.com/watch?v=fHAp1U7xNmc",
    "https://www.youtube.com/watch?v=zKjgTeMf3ME",
    "https://www.youtube.com/watch?v=SGFTQmBNuzk",
    "https://www.youtube.com/watch?v=ZsuizNGjtcc",
    "https://www.youtube.com/watch?v=5pURPGRfO9s",
    "https://www.youtube.com/watch?v=uIMBZNlqbpE",
    "https://www.youtube.com/watch?v=8yfKm-zoHXo",
    "https://www.youtube.com/watch?v=mKPefqm7hNo",
    "https://www.youtube.com/watch?v=CiCgW-upRCA",
    "https://www.youtube.com/watch?v=UV9KLw2_kMM",
    "https://www.youtube.com/watch?v=TXiQPon2M-8",
    "https://www.youtube.com/watch?v=TiZ2PO-PIwg",
    "https://www.youtube.com/watch?v=HOBv0UwKOmg",
    "https://www.youtube.com/watch?v=Unkd2V8QL9U",
    "https://www.youtube.com/watch?v=jFYIoIW17q8",
    "https://www.youtube.com/watch?v=sKn1PgjMRMA",
    "https://www.youtube.com/watch?v=07l0LbVFIdA",
    "https://www.youtube.com/watch?v=cOlexVYr8es",
    "https://www.youtube.com/watch?v=v2W5r9xevHo",
    "https://www.youtube.com/watch?v=Mj_7hJlwhK8",
    "https://www.youtube.com/watch?v=F3MSuOmYisI",
    "https://www.youtube.com/watch?v=Xnwvz6JGlLI",
    "https://www.youtube.com/watch?v=ATk2yciRauY",
    "https://www.youtube.com/watch?v=5ihu4ijWZ8M",
    "https://www.youtube.com/watch?v=s8C7IHO4m84",
    "https://www.youtube.com/watch?v=UBWEJQJRzoo",
    "https://www.youtube.com/watch?v=L7TdyyK7M6s",
    "https://www.youtube.com/watch?v=EuFqtpa8H4s",
    "https://www.youtube.com/watch?v=I1RNUY3Bouc",
    "https://www.youtube.com/watch?v=ptbW6ZOW3ws",
    "https://www.youtube.com/watch?v=5XEjwut41v8",
    "https://www.youtube.com/watch?v=xMNB-eiJ4CY",
    "https://www.youtube.com/watch?v=fFEk6nuaAyY",
    "https://www.youtube.com/watch?v=EqTo2xXW7jQ",
    "https://www.youtube.com/watch?v=i8AM01mc_1Q",
    "https://www.youtube.com/watch?v=k63BiPAoXgw",
    "https://www.youtube.com/watch?v=ysbxH7su4l8",
    "https://www.youtube.com/watch?v=ub199xe6Fn8",
    "https://www.youtube.com/watch?v=b9PoyAEi3oM",
    "https://www.youtube.com/watch?v=EMWjfq4VFfM",
    "https://www.youtube.com/watch?v=LNRJxmNFC8Y",
    "https://www.youtube.com/watch?v=dV_dnhJErTk",
    "https://www.youtube.com/watch?v=EDzg7D7lxeQ",
    "https://www.youtube.com/watch?v=649ZZKto0b8",
    "https://www.youtube.com/watch?v=iMK6Mj7NrHU",
    "https://www.youtube.com/watch?v=UGhB14H8QNA",
    "https://www.youtube.com/watch?v=eyki2TT3snU",
    "https://www.youtube.com/watch?v=Z8QekZlz--g",
    "https://www.youtube.com/watch?v=HZfxuJBGud4",
    "https://www.youtube.com/watch?v=j3KxSdvYt3g",
    "https://www.youtube.com/watch?v=cp2PQ6o-lgo",
    "https://www.youtube.com/watch?v=c1n56TK0YSU",
    "https://www.youtube.com/watch?v=QGnGb3JcVls",
    "https://www.youtube.com/watch?v=OQDpvqXb370",
    "https://www.youtube.com/watch?v=l-damV1JTKI",
    "https://www.youtube.com/watch?v=iFos6Qmyag0",
    "https://www.youtube.com/watch?v=VV5bycq4wrs",
    "https://www.youtube.com/watch?v=hIqvTcmUtkA",
    "https://www.youtube.com/watch?v=46g2jlNbm8c",
    "https://www.youtube.com/watch?v=8L108jf_7Gk",
    "https://www.youtube.com/watch?v=RGxgtYYa6LA",
    "https://www.youtube.com/watch?v=7LWPGoBAkbk",
    "https://www.youtube.com/watch?v=7SidOsD8j6Q",
    "https://www.youtube.com/watch?v=NbZqyADch9A",
    "https://www.youtube.com/watch?v=Zq8MriHrowc",
    "https://www.youtube.com/watch?v=vNrN1oIzNMU",
    "https://www.youtube.com/watch?v=FxysIJEkaZA",
    "https://www.youtube.com/watch?v=zhKy3-1A1K0",
    "https://www.youtube.com/watch?v=eRAiaB6iatM",
    "https://www.youtube.com/watch?v=AGeDUIHwt58",
    "https://www.youtube.com/watch?v=VaVhv78rJ2Y",
    "https://www.youtube.com/watch?v=39ulIYLQyfY",
    "https://www.youtube.com/watch?v=UeoVxAMOarI",
    "https://www.youtube.com/watch?v=In4cRN-dsCk",
    "https://www.youtube.com/watch?v=ehF0owCeYnI",
    "https://www.youtube.com/watch?v=Jz8ZS21_Mr8",
    "https://www.youtube.com/watch?v=ONwUYJ3WPFc",
    "https://www.youtube.com/watch?v=V8ocTAdUoDI",
    "https://www.youtube.com/watch?v=elmXyE-RZZY",
    "https://www.youtube.com/watch?v=QJrnBaVkXrQ",
    "https://www.youtube.com/watch?v=AVsRlyUesn4",
    "https://www.youtube.com/watch?v=vXkT8oLuLjQ",
    "https://www.youtube.com/watch?v=kzBpROfkfYs",
    "https://www.youtube.com/watch?v=C-V1fNsaY_s",
    "https://www.youtube.com/watch?v=OUXldJYxgow",
    "https://www.youtube.com/watch?v=_ZiCZWke5mo",
    "https://www.youtube.com/watch?v=jIFp5ezfmSQ",
    "https://www.youtube.com/watch?v=IhpaTBXDSDA",
    "https://www.youtube.com/watch?v=bA0mn0zvCbo",
    "https://www.youtube.com/watch?v=l_N_aW9MM2Y",
    "https://www.youtube.com/watch?v=IW2B_VLH7o4",
    "https://www.youtube.com/watch?v=Xxw2edvYWng",
    "https://www.youtube.com/watch?v=a21qsi1g8cc",
    "https://www.youtube.com/watch?v=RGBPJ82LEbA",
    "https://www.youtube.com/watch?v=VjupWRnTbRk",
    "https://www.youtube.com/watch?v=C4hap1nOmRo",
    "https://www.youtube.com/watch?v=622pZgX48rg",
    "https://www.youtube.com/watch?v=kj28VZZT2AQ",
    "https://www.youtube.com/watch?v=-iLKCHAzi8I",
    "https://www.youtube.com/watch?v=aEPV0CXCK1U",
    "https://www.youtube.com/watch?v=TUKQUq7gvB0",
    "https://www.youtube.com/watch?v=I6CqiTnC5iY"

]

const extra = {
    "thun-suthraya": `
    <div class="suthra-container">
            <div class="all-suthra">
            <div class="single-suthra">
               <h1 class="sc-h">සිංහල තේරුම සමග - Sinhala Meaning</h1>
               <h2 class="sc-h">මහා මංගල සුත්‍රය - Maha Mangala Suthraya</h2>
               <div class="row resRw">
                  <div class="col-md-6 cont2">
                     <p class="col-xs-11">එවං මෙ සුතං – එකං සමයං භගවා සාවත‍්ථියං විහරති ජෙතවනෙ අනාථපිණ‍්ඩිකස‍්ස ආරාමෙ. අථ ඛො අඤ‍්ඤතරා දෙවතා අභික‍්කන‍්තාය රත‍්තියා අභික‍්කන‍්තවණ‍්ණා කෙවලකප‍්පං ජෙතවනං ඔභාසෙත‍්වා යෙන භගවා තෙනුපසඞ‍්කමි; උපසඞ‍්කමිත‍්වා භගවන‍්තං අභිවාදෙත‍්වා එකමන‍්තං අට‍්ඨාසි. එකමන‍්තං ඨිතා ඛො සා දෙවතා භගවන‍්තං ගාථාය අජ‍්ඣභාසි –</p>
                  </div>
                  <div class="col-md-6">
                     <p class="sc-p">මා විසින් මෙසේ අසන ලදී: එක් සමයෙක්හි භාග්‍යවතුන් වහන්සේ සැවැත්නුවර දෙවුරම් නම් වූ අනාථපිණ්ඩික සිටුහුගේ අරම්හි වැඩ වසන සේක. ඉක්බිත්තෙන් ඉක්ම ගිය පෙර යම ඇති රැයෙක විසිතුරු සිරුරු පැහැයෙන් යුත් එක්තරා දෙවියෙක් මුලු මහත් දෙවුරම එකලු කොට භාග්‍යවතුන් වහන්සේ වෙත එළඹියේ ය. එළඹ භාග්‍යවතුන් වහන්සේ වැඳ එකත් පසෙක් සිටියේ ය. එකත්පසෙක සිටි ඒ දෙවි තෙමේ භාග්‍යවතුන් වහන්සේට ගාථාවකින් සැළකෙළේ ය:-</p>
                  </div>
               </div>
               <div class="row resRw">
                  <div class="col-md-6 cont2">
                     <p class="sc-p"></p>
                     <div class="row">
                        <div class="col-xs-1">260.</div>
                        <div class="col-xs-11">‘‘බහූ දෙවා මනුස‍්සා ච, මඞ‍්ගලානි අචින‍්තයුං;<br />ආකඞ‍්ඛමානා සොත‍්ථානං, බ්‍රෑහි මඞ‍්ගලමුත‍්තමං’’.</div>
                     </div>
                     <p></p>
                  </div>
                  <div class="col-md-6">
                     <p class="sc-p">සෙත් පතන්නාවූ බොහෝ දෙවියෝ ද, මනුෂ්‍යයෝ ද (සව් සැපට කරුණුවූ) මුඟුල් සිතූහ. (එහෙයින්) උතුම්වූ මඟුල් (කිම දැ යි) දෙසන සේක්වා!</p>
                  </div>
               </div>
               <div class="row resRw">
                  <div class="col-md-6 cont2">
                     <p class="sc-p"></p>
                     <div class="row">
                        <div class="col-xs-1">261.</div>
                        <div class="col-xs-11">‘‘අසෙවනා ච බාලානං, පණ‍්ඩිතානඤ‍්ච සෙවනා;<br />පූජා ච පූජනීයානං, එතං මඞ‍්ගලමුත‍්තමං.</div>
                     </div>
                     <p></p>
                  </div>
                  <div class="col-md-6">
                     <p class="sc-p">බාලයන් නො සෙවීම ද, පණ්ඩිතයන් සේවනය කිරීම ද, පිදිය යුත්තන් පිදීම ද, යන මෙය උතුම් මඟුලි.</p>
                  </div>
               </div>
               <div class="row resRw">
                  <div class="col-md-6 cont2">
                     <p class="sc-p"></p>
                     <div class="row">
                        <div class="col-xs-1">262.</div>
                        <div class="col-xs-11">‘‘පතිරූපදෙසවාසො ච, පුබ‍්බෙ ච කතපුඤ‍්ඤතා;<br />අත‍්තසම‍්මාපණීධී ච, එතං මඞ‍්ගලමුත‍්තමං.</div>
                     </div>
                     <p></p>
                  </div>
                  <div class="col-md-6">
                     <p class="sc-p">සුදුසු පියසෙක්හි විසීම ද, පෙර කළ පින් ඇතිබව ද, සිත මොනොවට පිහිටුවීම ද යන මෙය උතුම් මඟුලි.</p>
                  </div>
               </div>
               <div class="row resRw">
                  <div class="col-md-6 cont2">
                     <p class="sc-p"></p>
                     <div class="row">
                        <div class="col-xs-1">263.</div>
                        <div class="col-xs-11">‘‘බාහුසච‍්චඤ‍්ච සිප‍්පඤ‍්ච, විනයො ච සුසික‍්ඛිතො;<br />සුභාසිතා ච යා වාචා, එතං මඞ‍්ගලමුත‍්තමං.</div>
                     </div>
                     <p></p>
                  </div>
                  <div class="col-md-6">
                     <p class="sc-p">බොහෝ ඇසූ පිරූ තැන් ඇති බව ද, ශිල්පයෙහි දක්ෂ බව ද, (කාවිසිමුනු දොර ) හික්මවීම ද, (ආචාර ගුණව්‍යවස්ථානයෙන්) මොනොවට හික්මුනු බව ද. සුබැසි බස ද යන මෙය උතුම් මඟුලි.</p>
                  </div>
               </div>
               <div class="row resRw">
                  <div class="col-md-6 cont2">
                     <p class="sc-p"></p>
                     <div class="row">
                        <div class="col-xs-1">264.</div>
                        <div class="col-xs-11">‘‘මාතාපිතු උපට‍්ඨානං, පුත‍්තදාරස‍්ස සඞ‍්ගහො;<br />අනාකුලා ච කම‍්මන‍්තා, එතං මඞ‍්ගලමුත‍්තමං.</div>
                     </div>
                     <p></p>
                  </div>
                  <div class="col-md-6">
                     <p class="sc-p">මවුපිය උවටන ද, අඹුදරුවන් සැඟහීම ද, නිරවුල් වූ කර්මාන්ත ද යන මෙය උතුම් මඟුලි.</p>
                  </div>
               </div>
               <div class="row resRw">
                  <div class="col-md-6 cont2">
                     <p class="sc-p"></p>
                     <div class="row">
                        <div class="col-xs-1">265.</div>
                        <div class="col-xs-11">‘‘දානඤ‍්ච ධම‍්මචරියා ච, ඤාතකානඤ‍්ච සඞ‍්ගහො;<br />අනවජ‍්ජානි කම‍්මානි, එතං මඞ‍්ගලමුත‍්තමං.</div>
                     </div>
                     <p></p>
                  </div>
                  <div class="col-md-6">
                     <p class="sc-p">දන්දීම ද, (දසකුසල්) දම්හි හැසිරීම ද, නෑයනට සැඟහීම ද, නිරවද්‍යකර්ම ද යන මෙය උතුම් මඟුලි.</p>
                  </div>
               </div>
               <div class="row resRw">
                  <div class="col-md-6 cont2">
                     <p class="sc-p"></p>
                     <div class="row">
                        <div class="col-xs-1">266.</div>
                        <div class="col-xs-11">‘‘ආරතී විරතී පාපා, මජ‍්ජපානා ච සංයමො;<br />අප‍්පමාදො ච ධම‍්මෙසු, එතං මඞ‍්ගලමුත‍්තමං.</div>
                     </div>
                     <p></p>
                  </div>
                  <div class="col-md-6">
                     <p class="sc-p">(සිතින්) පවෙහි නො ඇල්ම ද, (ත්‍රිවිධ විරතීන්ගේ වශයෙන්) පවින් වැළකීම ද, මත්පැනින් වෙන් වීම ද, (කුසල්) දහම්හි නො පමා බව ද යන මෙය උතුම් මඟුලි.</p>
                  </div>
               </div>
               <div class="row resRw">
                  <div class="col-md-6 cont2">
                     <p class="sc-p"></p>
                     <div class="row">
                        <div class="col-xs-1">267.</div>
                        <div class="col-xs-11">‘‘ගාරවො ච නිවාතො ච, සන‍්තුට‍්ඨි ච කතඤ‍්ඤුතා;<br />කාලෙන ධම‍්මසවණං, එතං මඞ‍්ගලමුත‍්තමං.</div>
                     </div>
                     <p></p>
                  </div>
                  <div class="col-md-6">
                     <p class="sc-p">(බුදු-පසේ බුදු- බුදුසවු-ඇදුරු උවදෑ ඈනට) ගරු කිරීම ද, නිහතමාන බවද, ලද දැයින් සතුටු වීම ද, කළගුණ දන්නා බව ද, සුදුසු කල්හි දහම් ඇසීම ද යන යන මෙය උතුම් මඟුලි.</p>
                  </div>
               </div>
               <div class="row resRw">
                  <div class="col-md-6 cont2">
                     <p class="sc-p"></p>
                     <div class="row">
                        <div class="col-xs-1">268.</div>
                        <div class="col-xs-11">‘‘ඛන‍්තී ච සොවචස‍්සතා, සමණානඤ‍්ච දස‍්සනං;<br />කාලෙන ධම‍්මසාකච‍්ඡා, එතං මඞ‍්ගලමුත‍්තමං.</div>
                     </div>
                     <p></p>
                  </div>
                  <div class="col-md-6">
                     <p class="sc-p">ඉවසීම ද සුවච බව ද මහණුන් දැක්ම ද සුදුසු කල්හි ධර්ම සාකච්ඡාව ද යන මෙය උතුම් මඟුලි.</p>
                  </div>
               </div>
               <div class="row resRw">
                  <div class="col-md-6 cont2">
                     <p class="sc-p"></p>
                     <div class="row">
                        <div class="col-xs-1">269.</div>
                        <div class="col-xs-11">‘‘තපො ච බ්‍රහ‍්මචරියඤ‍්ච, අරියසච‍්චාන දස‍්සනං;<br />නිබ‍්බානසච‍්ඡිකිරියා ච, එතං මඞ‍්ගලමුත‍්තමං.</div>
                     </div>
                     <p></p>
                  </div>
                  <div class="col-md-6">
                     <p class="sc-p">(ඉන්ද්‍රිය සංවර වීර්‍ය්‍ය සඞ්ඛ්‍යාත) තපස ද බඹසර ද චතුරාර්‍ය්‍ය සත්‍යයන් දැකීම ද නිවන් පසක් කිරීම ද යන මෙය උතුම් මඟුලි.</p>
                  </div>
               </div>
               <div class="row resRw">
                  <div class="col-md-6 cont2">
                     <p class="sc-p"></p>
                     <div class="row">
                        <div class="col-xs-1">270.</div>
                        <div class="col-xs-11">‘‘ඵුට‍්ඨස‍්ස ලොකධම‍්මෙහි, චිත‍්තං යස‍්ස න කම‍්පති;<br />අසොකං විරජං ඛෙමං, එතං මඞ‍්ගලමුත‍්තමං.</div>
                     </div>
                     <p></p>
                  </div>
                  <div class="col-md-6">
                     <p class="sc-p">අටලෝ දහමින් පහස්නා ලද යමකුගේ සිත නො සැලේ ද ශොකයෙන් තොර ද පහ වූ කෙලෙස් රජස් ඇත්තේ ද බිය රහිත ද යන මෙය උතුම් මඟුලි.</p>
                  </div>
               </div>
               <div class="row resRw">
                  <div class="col-md-6 cont2">
                     <p class="sc-p"></p>
                     <div class="row">
                        <div class="col-xs-1">271.</div>
                        <div class="col-xs-11">‘‘එතාදිසානි කත‍්වාන, සබ‍්බත‍්ථමපරාජිතා;<br />සබ‍්බත‍්ථ සොත‍්ථිං ගච‍්ඡන‍්ති, තං තෙසං මඞ‍්ගලමුත‍්තම’’න‍්ති</div>
                     </div>
                     <p></p>
                  </div>
                  <div class="col-md-6">
                     <p class="sc-p">(ස්කන්ධ-ක්ලේස -අභිසංස්කාර-දේවපුත්‍ර‍මාර සඞ්ඛ්‍යාත) සියලු තන්හි නො පැරදුනාහු මෙබඳු මඞ්ගලයන් කොට (මෙලෝ පරලෝ) හැම තන්හි සැපයට පැමිණෙත් යි.</p>
                  </div>
               </div>
            </div>
            <div class="single-suthra">
               <h2 class="sc-h">රතන සුත්‍රය - Rathana Suthraya</h2>
               <div class="row resRw">
                  <div class="col-md-6 cont2">
                     <p class="sc-p"></p>
                     <div class="row">
                        <div class="col-xs-1">224.</div>
                        <div class="col-xs-11">යානීධ භූතානි සමාගතානි,<br />භුම‍්මානි වා යානි ව අන‍්තලික‍්ඛෙ;<br />සබ‍්බෙව භූතා සුමනා භවන‍්තු,<br />අථොපි සක‍්කච‍්ච සුණන‍්තු භාසිතං.</div>
                     </div>
                     <p></p>
                  </div>
                  <div class="col-md-6">
                     <p class="sc-p">මෙහි රැස්වූ යම් භූම්‍යස්ථ අමනුෂ්‍ය කෙනෙක් හෝ වෙත් ද යම් අන්තරීක්‍ෂයෙහි වැසි අමනුෂ්‍ය කෙනෙක් හො වෙත් ද, ඒ හැම භූතයෝ සොම්නස් වෙත් වා! තවද මාගේ භාසිතය සකසා අසත් වා.</p>
                  </div>
               </div>
               <div class="row resRw">
                  <div class="col-md-6 cont2">
                     <p class="sc-p"></p>
                     <div class="row">
                        <div class="col-xs-1">225.</div>
                        <div class="col-xs-11">තස‍්මා හි භූතා නිසාමෙථ සබ‍්බෙ,<br />මෙත‍්තං කරොථ මානුසියා පජාය;<br />දිවා ච රත‍්තො ච හරන‍්ති යෙ බලිං,<br />තස‍්මා හි නෙ රක‍්ඛථ අප‍්පමත‍්තා.</div>
                     </div>
                     <p></p>
                  </div>
                  <div class="col-md-6">
                     <p class="sc-p">එසෙයින් ම හැම අමනුෂ්‍යයෝ අසත්වා! මනුෂ්‍ය ප්‍ර‍ජාවට මෛත්‍රිකරව. යම් මනුෂ්‍ය කෙනෙක් දව රෑ දෙක්හි පුද එළවත් ද, එහෙයින් ම අප්‍ර‍මත්ත වූවාහු ඔවුන් රකුව.</p>
                  </div>
               </div>
               <div class="row resRw">
                  <div class="col-md-6 cont2">
                     <p class="sc-p"></p>
                     <div class="row">
                        <div class="col-xs-1">226.</div>
                        <div class="col-xs-11">යං කිඤ‍්චි විත‍්තං ඉධ වා හුරං වා,<br />සග‍්ගෙසු වා යං රතනං පණීතං;<br />න නො සමං අත‍්ථි තථාගතෙන,<br />ඉදම‍්පි බුද‍්ධෙ රතනං පණීතං;<br />එතෙන සච‍්චෙන සුවත‍්ථි හොතු.</div>
                     </div>
                     <p></p>
                  </div>
                  <div class="col-md-6">
                     <p class="sc-p">මෙ මිනිස් ලොවැ හෝ නාග සූපර්‍ණාදී අන් ලොවෙකැ හෝ යම් ධනයෙකුත් වේද, ස්වර්ගයෙහි හෝ යම් ප්‍රණීත වූ රත්නයෙකුත් වේද, ඔවුනතුර තථාගතයන් හා සම එක ද රුවනෙක් නැත, මෙ ද බුදුරදුන් කෙරෙහි වූ ශ්‍රෙෂ්ඨ රත්න භාවයයි. ඒ සත්‍යයෙන් සත්නට සෙත් වේවා!</p>
                  </div>
               </div>
               <div class="row resRw">
                  <div class="col-md-6 cont2">
                     <p class="sc-p"></p>
                     <div class="row">
                        <div class="col-xs-1">227.</div>
                        <div class="col-xs-11">ඛයං විරාගං අමතං පණීතං,<br />යදජ‍්ඣගා සක්‍යමුනී සමාහිතො;<br />න තෙන ධම‍්මෙන සමත‍්ථි කිඤ‍්චි,<br />ඉදම‍්පි ධම‍්මෙ රතනං පණීතං;<br />එතෙන සච‍්චෙන සුවත‍්ථි හොතු.</div>
                     </div>
                     <p></p>
                  </div>
                  <div class="col-md-6">
                     <p class="sc-p">(ආර්‍ය්‍යමාර්‍ග සමාධියෙන්) සමාහිත වූ ශාක්‍යමුනි රාගාදී කෙලෙසුන්ගෙන් දුරු වූ හෙයින් විරාග නම් වූ ක්ලේශ ක්ෂය වූ අමෘත වූ ප්‍ර‍ණීත වූ යම් නිවනක් අවබෝධ කෙළේ ද, ඒ නිර්‍වාණ ධර්මය හා සමවූ අන් කිසිදු රුවනෙක් නැත, මෙද ධර්මයෙහි ශ්‍රේෂ්ඨ රත්න භාවයයි. ඒ සත්‍යයෙන් (සත්නට) සෙත් වේවා!</p>
                  </div>
               </div>
               <div class="row resRw">
                  <div class="col-md-6 cont2">
                     <p class="sc-p"></p>
                     <div class="row">
                        <div class="col-xs-1">228.</div>
                        <div class="col-xs-11">යං බුද‍්ධසෙට‍්ඨො පරිවණ‍්ණයී සුචිං,<br />සමාධිමානන‍්තරිකඤ‍්ඤමාහු;<br />සමාධිනා තෙන සමො න විජ‍්ජති,<br />ඉදම‍්පි ධම‍්මෙ රතනං පණීතං;<br />එතෙන සච‍්චෙන සුවත‍්ථි හොතු.</div>
                     </div>
                     <p></p>
                  </div>
                  <div class="col-md-6">
                     <p class="sc-p">බුද්ධශ්‍රේෂ්ඨයන් වහන්සේ (කෙළෙස් මල මුලුසුන් කරන හෙයින්) අත්‍යන්තයෙන් ව්‍යවදාන වූ යම් මාර්‍ගධර්මයක් වර්‍ණනා කළ සේක්ද, යම් ඵල සමාධියක් (මාර්‍ගයටඅනතුරුවම පල දෙන බැවින්) ආනන්තරිකය’යි වදාළ සේක් ද, ඒ සමාධිය හා සම වූ අනෙක් රුවනෙක් නැත.  මෙ ද ධර්මයෙහි ශ්‍රේෂ්ඨ රත්න භාවයයි. ඒ සත්‍යයෙන් සත්නට සෙත් වේවා!</p>
                  </div>
               </div>
               <div class="row resRw">
                  <div class="col-md-6 cont2">
                     <p class="sc-p"></p>
                     <div class="row">
                        <div class="col-xs-1">229.</div>
                        <div class="col-xs-11">යෙ පුග‍්ගලා අට‍්ඨ සතං පසත‍්ථා,<br />චත‍්තාරි එතානි යුගානි හොන‍්ති;<br />තෙ දක‍්ඛිණෙය්‍යා සුගතස‍්ස සාවකා,<br />එතෙසු දින‍්නානි මහප‍්ඵලානි;<br />ඉදම‍්පි සඞ‍්ඝෙ රතනං පණීතං,<br />එතෙන සච‍්චෙන සුවත‍්ථි හොතු.</div>
                     </div>
                     <p></p>
                  </div>
                  <div class="col-md-6">
                     <p class="sc-p">බුද්ධාදි සත්පුරුෂයන් විසින් පසස්නා ලද යම් අට අරී පුඟුල් කෙනෙක් වෙත් ද, තෙල පුරුෂ යුගලයෝ සතර දෙනෙක් වෙති. බුද්ධ ශ්‍රාවක වූ ඔහු දක්ෂිණාර්භ වෙති. තුලුනු කෙරෙහි දන්හු මහත් ඵල වෙති. මෙ ද සඞ්ගයා කෙරෙහි ශ්‍රේෂ්ඨ රත්න භාවය වේ. තෙල සත්‍යයෙන් සත්නට සෙත් වේවා!</p>
                  </div>
               </div>
               <div class="row resRw">
                  <div class="col-md-6 cont2">
                     <p class="sc-p"></p>
                     <div class="row">
                        <div class="col-xs-1">230.</div>
                        <div class="col-xs-11">යෙ සුප‍්පයුත‍්තා මනසා දළ‍්හෙන,<br />නික‍්කාමිනො ගොතමසාසනම‍්හි;<br />තෙ පත‍්තිපත‍්තා අමතං විගය‍්හ,<br />ලද‍්ධා මුධා නිබ‍්බුතිං භුඤ‍්ජමානා;<br />ඉදම‍්පි සඞ‍්ඝෙ රතනං පණීතං,<br />එතෙන සච‍්චෙන සුවත‍්ථි හොතු.</div>
                     </div>
                     <p></p>
                  </div>
                  <div class="col-md-6">
                     <p class="sc-p">යම් රහත් කෙනෙක් ගෞතම සාසනයෙහි දැඩි සිතින් මොනොවට විදර්‍ශනායෙහි ප්‍ර‍යුක්ත වූවාහු (ප්‍ර‍ඥා වීර්‍ය්‍ය ධූර දෙකින්) සියලු කෙළෙසුන්ගෙන් නික්මුනාහු වෙත් ද, ඔවුහු අර්හත් ඵල සඞ්ඛ්‍යාත ප්‍රාප්තියට පැමිණයාහු නිර්‍වාණ නම් වූ අමෘතයට බැස ගෙන නිවන් ලැබ ව්‍යය රහිතව වළදනාහු වෙති. මෙ ද සඞ්ඝයා කෙරෙහි ශ්‍රේෂ්ඨ රත්න භාවයයි. ඒ සත්‍යයෙන් (සත්නට) සෙත් වේවා!</p>
                  </div>
               </div>
               <div class="row resRw">
                  <div class="col-md-6 cont2">
                     <p class="sc-p"></p>
                     <div class="row">
                        <div class="col-xs-1">231.</div>
                        <div class="col-xs-11">යථින‍්දඛීලො පඨවිං සිතො සියා,<br />චතුබ‍්භි වාතෙහි අසම‍්පකම‍්පියො;<br />තථූපමං සප‍්පුරිසං වදාමි,<br />යො අරියසච‍්චානි අවෙච‍්ච පස‍්සති;<br />ඉදම‍්පි සඞ‍්ඝෙ රතනං පණීතං,<br />එතෙන සච‍්චෙන සුවත‍්ථි හොතු.</div>
                     </div>
                     <p></p>
                  </div>
                  <div class="col-md-6">
                     <p class="sc-p">යම් (‍සෝවාන්) පුඟුලෙක් චතුරාර්‍ය්‍යසත්‍යයන් මාර්‍ග ප්‍ර‍ඥාවෙන් විනිවිද දක්නේ ද, ඒ සත්පුරුෂ පොළොවෙහි සිටුවන ලද ඉන්ද්‍ර‍කීලය සිවු දිගින් හමන සුළඟින් යම් සේ නොසැලිය හේ ද, එ සත්පුරුෂ එවැනි උපමා ඇතිකොට කියමි. මෙ ද සඞ්ඝරත්නය කෙරෙහි ශ්‍රේෂ්ඨ රත්න භාවයයි. තෙල සත්‍යයෙන් සත්නට සෙත් වේවා!</p>
                  </div>
               </div>
               <div class="row resRw">
                  <div class="col-md-6 cont2">
                     <p class="sc-p"></p>
                     <div class="row">
                        <div class="col-xs-1">232.</div>
                        <div class="col-xs-11">යෙ අරියසච‍්චානි විභාවයන‍්ති,<br />ගම‍්භීරපඤ‍්ඤෙන සුදෙසිතානි;<br />කිඤ‍්චාපි තෙ හොන‍්ති භුසං පමත‍්තා,<br />න තෙ භවං අට‍්ඨමමාදියන‍්ති;<br />ඉදම‍්පි සඞ‍්ඝෙ රතනං පණීතං,<br />එතෙන සච‍්චෙන සුවත‍්ථි හොතු.</div>
                     </div>
                     <p></p>
                  </div>
                  <div class="col-md-6">
                     <p class="sc-p">(සත්තක්ඛත්තුපරම යි කියන ලද) යම් පුද්ගල කෙනෙක් ගැඹුරු පැණ ඇති බුදුරජුන් විසින් මොනොවට දෙසන ලද ආර්‍ය්‍යසත්‍යයන් (ප්‍ර‍ඥාවභාසයන්) ප්‍ර‍කට කෙරෙත් ද, ඔවුහු (දෙව්රජසිරි, සක්විතිරජසිරි ඈ ලබා) සසර බෙහෙවින් පමාවූවාහු නමුදු ඔහු අටවැනි භවයක් (උපත් විසින්) නො ගනිත්. මෙ ද සඞ්ඝයා කෙරෙහි ශ්‍රේෂ්ඨ රත්න භාවයයි. තෙල සත්‍යයෙන් සත්නට සෙත් වේවා!</p>
                  </div>
               </div>
               <div class="row resRw">
                  <div class="col-md-6 cont2">
                     <p class="sc-p"></p>
                     <div class="row">
                        <div class="col-xs-1">233.</div>
                        <div class="col-xs-11">සහාවසද‍්දස‍්සනසම‍්පදාය,<br />තයස‍්සු ධම‍්මා ජහිතා භවන‍්ති;<br />සක‍්කායදිට‍්ඨි විචිකිච‍්ඡිතඤ‍්ච,<br />සීලබ‍්බතං වාපි යදත‍්ථි කිඤ‍්චි.<br />චතූහපායෙහි ච විප‍්පමුත‍්තො,<br />ඡ චාභිඨානානි අභබ‍්බො කාතුං;<br />ඉදම‍්පි සඞ‍්ඝෙ රතනං පණීතං,<br />එතෙන සච‍්චෙන සුවත‍්ථි හොතු.</div>
                     </div>
                     <p></p>
                  </div>
                  <div class="col-md-6">
                     <p class="sc-p">ඔහුගේ (සොත්පත්තිමග්ගසම්පත්තිසඞඛ්‍යාත) දර්‍ශන සම්පත් සමග මැ සත්කාය දෘෂ්ටි ද විච්කිත්සා ද ශීලව්‍ර‍පරාමර්‍ශ දැයි යන යම් කිසිවක් ඇත් ද, ඒ ධර්මයෝ තුන්දෙන (ප්‍ර‍හීණ කිරීම් වශයෙන්) හරණා ලද වෙති. සිවු අපායෙනුදු හෙ විමුක්ත වෙයි. (පඤ්ච ආනන්තර්‍ය්‍ය හා අන්‍යශාස්තෘ උද්දේශකය ද යන) ඖදාරික කර්ම සය කිරීමට ද නොනිසි වෙයි. මෙ ද සඞ්ඝයා කෙරෙහි ශ්‍රේෂ්ඨ රත්න භාවයයි. තෙල සත්‍යයෙන් සත්නට සෙත් වේවා!</p>
                  </div>
               </div>
               <div class="row resRw">
                  <div class="col-md-6 cont2">
                     <p class="sc-p"></p>
                     <div class="row">
                        <div class="col-xs-1">234.</div>
                        <div class="col-xs-11">කිඤ‍්චාපි සො කම‍්මං කරොති පාපකං,<br />කායෙන වාචා උද චෙතසා වා;<br />අභබ‍්බො සො තස‍්ස පටිච‍්ඡාදාය,<br />අභබ‍්බතා දිට‍්ඨපදස‍්ස වුත‍්තා;<br />ඉදම‍්පි සඞ‍්ඝෙ රතනං පණීතං,<br />එතෙන සච‍්චෙන සුවත‍්ථි හොතු.</div>
                     </div>
                     <p></p>
                  </div>
                  <div class="col-md-6">
                     <p class="sc-p">තව ද හේ කයින් වේවයි බසින් වේවයි යලි සිතින් වේවයි පව්කම් කෙරේ නමුදු, එය පිළිපන් කිරීමට අභව්‍ය වෙයි. දුටු නිර්වාණ පද ඇතියහුගේ (දර්‍ශන සම්පන්න පුද්ගලයාගේ) (ඒ) අභව්‍යතාව කියන ලදු. මෙද සඞ්ඝයා කෙරෙහි ශ්‍රේෂ්ඨ රත්න භාවයයි. තෙල සත්‍යයෙන් සත්නට සෙත් වේවා!</p>
                  </div>
               </div>
               <div class="row resRw">
                  <div class="col-md-6 cont2">
                     <p class="sc-p"></p>
                     <div class="row">
                        <div class="col-xs-1">235.</div>
                        <div class="col-xs-11">වනප‍්පගුම‍්බෙ යථා ඵුස‍්සිතග‍්ගෙ,<br />ගිම‍්හානමාසෙ පඨමස‍්මිං ගිම‍්හෙ;<br />තථූපමං ධම‍්මවරං අදෙසයී,<br />නිබ‍්බානගාමිං පරමං හිතාය;<br />ඉදම‍්පි බුද‍්ධෙ රතනං පණීතං,<br />එතෙන සච‍්චෙන සුවත‍්ථි හොතු.</div>
                     </div>
                     <p></p>
                  </div>
                  <div class="col-md-6">
                     <p class="sc-p">ග්‍රීෂ්ම ඍතුයෙහි ප්‍ර‍ථම ග්‍රීෂ්ම මාසයෙහි, (බක් මසැ) පිපී ගිය මල් බර අතු පතර ඇති වන ගොමුවක් යම් සේ වේ ද, එය උපමා කොට ඇති අත්‍යුත්තම වූ නිර්‍වාණගාමී වූ (පර්‍ය්‍යාප්ති) ධර්මය (සත්‍වයනට) හිත පිණිස දෙසූහ. මෙ ද බුදුරදුන් කෙරෙහි වූ ශ්‍රේෂ්ඨ රත්න භාවයයි. තෙල සත්‍යයෙන් සත්නට සෙත් වේවා!</p>
                  </div>
               </div>
               <div class="row resRw">
                  <div class="col-md-6 cont2">
                     <p class="sc-p"></p>
                     <div class="row">
                        <div class="col-xs-1">236.</div>
                        <div class="col-xs-11">වරො වරඤ‍්ඤූ වරදො වරාහරො,<br />අනුත‍්තරො ධම‍්මවරං අදෙසයි;<br />ඉදම‍්පි බුද‍්ධෙ රතනං පණීතං,<br />එතෙන සච‍්චෙන සුවත‍්ථි හොතු.</div>
                     </div>
                     <p></p>
                  </div>
                  <div class="col-md-6">
                     <p class="sc-p">(සර්වඥතාඥානලාබයෙන්) උතුම් වූ, (නිර්වාණ ධර්ම සංඛ්‍යාත) වරය දන්නා වූ, (දෙවි මිනිස්නට නිර්වේද භාගීය වාසනා භාගීය) වරධර්මය දෙන්නා වූ, (ආර්යමාර්ගසංඛ්‍යාත) වර එළවන්නාවූ, අනුත්තර බුදුහු ලොකෝත්තර ධර්මය දෙසූහ. මෙ ද බුදුන් කෙරෙහි ශ්‍රේෂ්ඨ රත්න භාව යි. තෙල සත්‍යයෙන් සත්නට සෙත් වේවා!</p>
                  </div>
               </div>
               <div class="row resRw">
                  <div class="col-md-6 cont2">
                     <p class="sc-p"></p>
                     <div class="row">
                        <div class="col-xs-1">237.</div>
                        <div class="col-xs-11">ඛීණං පුරාණං නව නත‍්ථි සම‍්භවං,<br />විරත‍්තචිත‍්තායතිකෙ භවස‍්මිං;<br />තෙ ඛීණබීජා අවිරූළ‍්හිඡන‍්දා,<br />නිබ‍්බන‍්ත‍්න‍්ත‍්ති ධීරා යථායං පදීපො;<br />ඉදම‍්පි සඞ‍්ඝෙ රතනං පණීතං,<br />එතෙන සච‍්චෙන සුවත‍්ථි හොතු.</div>
                     </div>
                     <p></p>
                  </div>
                  <div class="col-md-6">
                     <p class="sc-p">(රහත්හුගේ) පැරණි කර්ම ගෙවී ගියේ ය. නව (කර්ම) ප්‍රාදූර්භූතභාවයෙක් නැත. පුනර්භවයෙහි පහ වූ රාගසිත් ඇති ගෙවුනු කර්ම බීජ ඇති, නො වැඩෙන ඡන්දරාග ඇති, ඒ පණ්ඩිත වූ ක්ෂීණශ්‍රාවයෝ මේ පහන සෙයින් නිවෙත්. මෙ ද සඞ්ඝයා කෙරෙහි ශ්‍රේෂ්ඨ රත්න භාව යි. තෙල සත්‍යයෙන් සත්නට සෙත් වේවා!</p>
                  </div>
               </div>
               <div class="row resRw">
                  <div class="col-md-6 cont2">
                     <p class="sc-p"></p>
                     <div class="row">
                        <div class="col-xs-1">238.</div>
                        <div class="col-xs-11">යානීධ භූතානි සමාගතානි,<br />භුම‍්මානි වා යානි ව අන‍්තලික‍්ඛෙ;<br />තථාගතං දෙවමනුස‍්සපූජිතං,<br />බුද‍්ධං නමස‍්සාම සුවත‍්ථි හොතු.</div>
                     </div>
                     <p></p>
                  </div>
                  <div class="col-md-6">
                     <p class="sc-p">මෙහි රැස් වූ යම් භූමිත්‍ථ වූ අමනුෂ්‍ය කෙනෙක් වෙත් ද, යම් ආකාශස්ථ අමනුෂ්‍ය කෙනෙක් හෝ වෙත් ද, (අපි) දෙවිමිනිසුන් විසින් පුදන ලද තථාගත බුදුරජුන් නමදිම්හ. සත්හට සෙත් වේවා !</p>
                  </div>
               </div>
            </div>


            <div class="single-suthra">

               <h2 class="sc-h">කරණීයමත්ත සුත්‍රය - karaniyamaththa Suthraya</h2>
               <div class="row resRw">
                  <div class="col-md-6 cont2">
                     <p class="col-xs-11">කරණීයමත‍්ථකුසලෙන<br />යන‍්තසන‍්තං පදං අභිසමෙච‍්ච<br />
                        සක‍්කො උජූ ච<br />සූජූ ච, සුවචො චස‍්ස මුදු අනතිමානී.
                     </p>
                  </div>
                  <div class="col-md-6">
                     <p class="sc-p">යම් ඒ ශාන්තිපද සඞ්ඛ්‍යාත නිර්‍වාණයෙක් වේ ද, එයට පැමිණ නොහොත් එය අවබොධ කොට වසනු කැමති අභිවෘද්ධිය සලසා ගැණීමෙහි දක්‍ෂයා විසින් එයට සුදුසු දෙය කළ යුතු ය. නිවණට පැමිණ වසනු කැමැත්තේ සත්‍යප්‍රතිවෙධය පිණිස පිළිපදින්නට නොහොත් කිසුණු පිරියම් කිරීම් වත් සමාදන් වීම් ආදී සියලු කාර්‍ය්‍යයන් කරන්නට දක්‍ෂයෙක් වන්නේ ය. (දක්‍ෂයකු විය යුතු ය.) ඍජු වන්නේ ය. එක් වරක් ඍජු වීමෙන් නො නැවතී දිවි ඇති තාක් මනා කොට ඍජු වන්නේ ය. අන්‍යයන් ගේ අවවාද මනාකොට පිළිගැණීම් වශයෙන් සුවච වන්නේ ය. මෘදු වන්නේ ය. අතිමාන ඇත්තේ නො වන්නේ ය.</p>
                  </div>
               </div>
               <div class="row resRw">
                  <div class="col-md-6 cont2">
                     <p class="col-xs-11">සන‍්තුස‍්සකො ච සුභරො ච<br />අප‍්පකිච‍්චො ච සල‍්ලහුකවුත‍්ති<br />
                        සන‍්තින‍්ද්‍රියො ච නිපකො ච<br />අප‍්පගබ‍්භො කුලෙසු අනනුගිද‍්ධො.
                     </p>
                  </div>
                  <div class="col-md-6">
                     <p class="sc-p">ලද දෙයින් සතුටු වන්නෙක් ද පහසුවෙන් පෝෂණය කළ හැක්කෙක් ද, (ස්වකීය ශ්‍රමණධර්‍මය කිරීමට පමණක් සිමා වූ ) ස්වල්ප කටයුතු ඇත්තෙක් ද, සැහැල්ලු පැවතුම් ඇත්තෙක් ද, සන්හුන් ඉඳුරන් ඇත්තෙක් ද ප්‍රඥා ඇත්තෙක් ද, හැඩි දැඩි අසංවර ගති නැත්තෙක් ද, කුලයන් කෙරෙහි (සිවු පසයෙහි ඇලීම් ආදී වශයෙන්) නො ඇලුනෙක් ද වන්නේ ය.</p>
                  </div>
               </div>
               <div class="row resRw">
                  <div class="col-md-6 cont2">
                     <p class="col-xs-11">න ච ඛුද‍්දමාචරෙ<br />කිඤ‍්චි යෙන විඤ‍්ඤූ පරෙ උපවදෙය්‍යුං<br />
                        සුඛිනොව ඛෙමිනො හොන‍්තු<br />සබ‍්බසත‍්තා භවන‍්තු සුඛිතත‍්තා.
                     </p>
                  </div>
                  <div class="col-md-6">
                     <p class="sc-p">නුවණැති අන්‍යයන් නින්‍දා කරණ සුළු වරදක් වත් නො කරන්නේ ය. “සියලු සත්ත්‍වයෝ සැප ඇත්තෝ වෙත්වා, භය නැත්තෝ වෙත් වා, සුඛිත සිත් ඇත්තෝ වෙත් වා”යි ද,</p>
                  </div>
               </div>
               <div class="row resRw">
                  <div class="col-md-6 cont2">
                     <p class="col-xs-11">යෙ කෙචි පාණභූතත‍්ථි<br /> තසා වා ථාවරා වනවසෙසා<br />
                        දීඝා වා යෙව මහන‍්තා වා<br />මජ‍්ඣිමා රස‍්සකා අණුකථූලා.
                     </p>
                  </div>
                  <div class="col-md-6">
                     <p class="sc-p">තෘෂ්ණාභය සහිත හෙයින් තැති ගන්නා සුලු වූ ද තෘෂ්ණා භය ප්‍රහීණ කළ හෙයින් ස්ථාවර වූ ද යම් කිසි සත්ත්‍වයෝ ඇත්තාහු නම්,  ඒ සියලුදෙනා ද, (නාගාදි) දික් ශරීර ඇත්තෝ ද, (මත්ස්‍ය හස්ති අසුරා දී) මහත් ශරීර ඇත්තෝ ද, (අශ්වගවමහිෂාදී) මධ්‍යම ප්‍රමාණ ශරීර ඇත්තෝ ද, (වාමනාදී) මිටි සිරුරු ඇත්තෝ ද, (පියෙවි ඇසට නොපෙණෙන) ඉතා සියුම් ශරීර ඇත්තෝ ද, (මස් කැසුබු ආදී) ස්ථුල ශරීර ඇත්තෝ ද,</p>
                  </div>
               </div>
               <div class="row resRw">
                  <div class="col-md-6 cont2">
                     <p class="col-xs-11">දිට‍්ඨා වා යෙව අදිට‍්ඨා <br />යෙ ච දූරෙ වසන‍්ති අවිදූරෙ<br />
                        භූතා වා සම‍්භවෙසී වා<br />සබ‍්බසත‍්තා භවන‍්තු සුඛිතත‍්තා.
                     </p>
                  </div>
                  <div class="col-md-6">
                     <p class="sc-p">ඇස් හමුවට පැමිණීම් වශයෙන් දුටු සත්තු ද, ඈත සිටි හෙයින් නුදුටු සත්තු ද, යම් සත්ත්‍ව කෙනෙක් දුරත් ලඟත් වසත් නම් ඔහු ද, ඉපදීම අවසන් කළ සත්ත්‍වයෝ ද (ක්‍ෂිණාශ්‍රවයෝ ද), මතු ඉපදීම සොයන (ශෛක්‍ෂ පෘථග්ජන) සත්ත්‍වයෝ ද යන මේ සියලු සත්ත්‍වයෝ සුඛිත සිත් ඇත්තෝ වෙත්’වා යි ද මෙත් වඩන්නේ ය.</p>
                  </div>
               </div>
               <div class="row resRw">
                  <div class="col-md-6 cont2">
                     <p class="col-xs-11">න පරො පරං නිකුබ‍්බෙථ<br />නාතිමඤ‍්ඤෙථ කත‍්ථචි නං කඤ‍්චි <br />
                        බ්‍යාරොසනා පටිඝසඤ‍්ඤා<br />නාඤ‍්ඤමඤ‍්ඤස‍්ස දුක‍්ඛමිච‍්ඡෙය්‍ය.
                     </p>
                  </div>
                  <div class="col-md-6">
                     <p class="sc-p">එකෙක් අනෙකකු නො රවටන්නේ ය. (නො රවටාවා) අනෙකකුට අවමන් නො කරන්නේ ය. (නො කෙරේ වා) බ්‍යාරොසනා නම් වූ කය හා වචනය පිළිබඳ විකෘතියෙන් ද ප්‍රතිඝසංඥා සඞ්ඛ්‍යාත මනෝවිකාරයෙන් ද එකෙක් අනෙකකුට දුකක් නො කැමති වන්නේ ය. (නො කැමති වේවා).</p>
                  </div>
               </div>
               <div class="row resRw">
                  <div class="col-md-6 cont2">
                     <p class="col-xs-11">මාතා යථා නියං පුත‍්තං<br />ආයුසා එකපුත‍්තමනුරක‍්ඛෙ<br />
                        එවම‍්පි සබ‍්බභූතෙසු<br />මානසං භාවයෙ අපරිමාණං.
                     </p>
                  </div>
                  <div class="col-md-6">
                     <p class="sc-p">මව යම්සේ තමා ගෙන් ඇති වූ එක ම ඖරස පුත්‍රයා ජීවිතපරිත්‍යාගයෙන් වූවත් රක්නේ ද, එ පරිද්දෙන් ම සියලු සත්ත්‍වයන් කෙරෙහි අප්‍රමාණ වූ මෛත්‍රීසහගත සිත වඩන්නේ ය.</p>
                  </div>
               </div>
               <div class="row resRw">
                  <div class="col-md-6 cont2">
                     <p class="col-xs-11">මෙත‍්තඤ‍්ච සබ‍්බලොකස‍්මි<br />මානසං භාවයෙ අපරිමාණං<br />
                        උද‍්ධං අධො ච තිරියඤ‍්ච<br />අසම‍්බාධං අවෙරමසපත‍්තං.
                     </p>
                  </div>
                  <div class="col-md-6">
                     <p class="sc-p">උඩ ද යට ද සරස ද (සතුරන් ය යි කියන ලද) සීමාවකුදු නැති ව අතරතුර වත් වෛර චේතනා පහළ වීමක් නැති ව, සතුරන් රහිත ව සියලු ලෝකසත්ත්‍වයා කෙරෙහි අප්‍රමාණ වූ මෛත්‍රීසහගත සිත වඩන්නේ ය.</p>
                  </div>
               </div>
               <div class="row resRw">
                  <div class="col-md-6 cont2">
                     <p class="col-xs-11">තිට‍්ඨං චරං නිසින‍්නො වා<br />සයානො යාවතාස‍්ස විගතමිද‍්ධො<br />
                        එතං සතිං අධිට‍්ඨෙය්‍ය<br />බ්‍රහ‍්මමෙතං විහාරමිධමාහු.
                     </p>
                  </div>
                  <div class="col-md-6">
                     <p class="sc-p">සිටින්නේ හෝ ඇවිදින්නේ හෝ උන්නේ හෝ නිදන්නේ හෝ යම් තාක් මෛත්‍රීසහගත සිහිය ඉටනු කැමැත්තේ ද, ඒ තාක් පහ වූ මිද්ධය (අලස බව) ඇති ව ඉටන්නේ ය. මේ මෛත්‍රීවිහරණය මේ බුද්ධශාසනයෙහි ශ්‍රේෂ්ඨ විහරණයැ යි ප්‍රකාශ කළහ.</p>
                  </div>
               </div>
               <div class="row resRw">
                  <div class="col-md-6 cont2">
                     <p class="col-xs-11">දිට‍්ඨිඤ‍්ච අනුපග‍්ගම‍්ම<br />සීලවා දස‍්සනෙන සම‍්පන‍්නො<br />
                        කාමෙසු විනෙය්‍ය ගෙධං<br />න හි ජාතුග‍්ගබ‍්භසෙය්‍ය පුන රෙතීති.
                     </p>
                  </div>
                  <div class="col-md-6">
                     <p class="sc-p">(සත්ත්‍වයෙක් පුද්ගලයෙක් ඇත්තේ ය යන) මිථ්‍යදෘෂ්ටිය නො පැමිණ (ලොකොත්තරශීලයෙන්) සිල්වත් වූයේ (සෝතාපත්තිමග්ගසම්මාදිට්ඨි ය යි කියන ලද) දර්‍ශනයෙන් යුක්ත වූයේ (සකෘදාගාමි අනාගාමී මාර්‍ග දෙකින්) වස්තුකාමයන්හි ගිජුබව දුරු කොට, ඒකාන්තයෙන් ම නැවත ගර්‍භයෙක් හි විසීමට නො පැමිණෙන්නේ ය. (ශුද්ධාවාසයයෙක ඉපිද එහි දි ම රහත්බවට පැමිණ පිරිනිවන් පාන්නේ ය.)</p>
                  </div>
               </div>
            </div>
         </div>
         </div>
    `
}

exports.list = list
exports.preachers = preachers
exports.extra = extra