import './App.css';
import React, { Suspense, useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import KeyPage from "./KeyPage";
import HomePage from "./HomePage";
import { FaYoutube, FaFacebook, FaTiktok } from 'react-icons/fa';


//import Stars from './particles';
const Stars = React.lazy(() => import("./particles"));

function App() {


  const [url, setUrl] = useState(encodeURIComponent(window.location.href));

  useEffect(() => {
    const updateUrl = () => {
      setUrl(encodeURIComponent(window.location.href));
    };

    // Detect URL changes
    const observer = new MutationObserver(() => {
      updateUrl();
    });

    observer.observe(document, { subtree: true, childList: true });

    // Cleanup observer
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div>
      <div className="inner-container" onClick={() => { window.location.href = "/" }}>
        <Suspense fallback={<div className='tsparticles'></div>}>
          <Stars />
          <img
            src="/images/image2-min2.png"
            srcSet="/images/mobile/image2-min2.png 600w, /images/image2-min2.png 1024w"
            sizes="(max-width: 600px) 100vw, 50vw"
            alt="Image 2"
            className="image2"
          />
        </Suspense>
        <img
          src="/images/image3-min.png"
          srcSet="/images/mobile/image3-min.png 600w, /images/image3-min.png 1024w"
          sizes="(max-width: 600px) 100vw, 50vw"
          alt="Image 3"
          className="image3"
        />
        <img
          src="/images/image4-min.png"
          srcSet="/images/mobile/image4-min.png 600w, /images/image4-min.png 1024w"
          sizes="(max-width: 600px) 100vw, 50vw"
          alt="Image 4"
          className="image4"
        />
      </div>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/:key" element={<KeyPage />} />
        </Routes>
      </Router>

      <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginTop: '20px', padding: '20px', background: '#333' }}>

        <a class="resp-sharing-button__link" href={"https://facebook.com/sharer/sharer.php?u=" + url} target="_blank" rel="noopener" aria-label="Share on Facebook">
          <div class="resp-sharing-button resp-sharing-button--facebook resp-sharing-button--large"><div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.77 7.46H14.5v-1.9c0-.9.6-1.1 1-1.1h3V.5h-4.33C10.24.5 9.5 3.44 9.5 5.32v2.15h-3v4h3v12h5v-12h3.85l.42-4z" /></svg>
          </div>Share on Facebook</div>
        </a>


        <a class="resp-sharing-button__link" href={"whatsapp://send?text=" + url} target="_blank" rel="noopener" aria-label="Share on WhatsApp">
          <div class="resp-sharing-button resp-sharing-button--whatsapp resp-sharing-button--large"><div aria-hidden="true" class="resp-sharing-button__icon resp-sharing-button__icon--solid">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20.1 3.9C17.9 1.7 15 .5 12 .5 5.8.5.7 5.6.7 11.9c0 2 .5 3.9 1.5 5.6L.6 23.4l6-1.6c1.6.9 3.5 1.3 5.4 1.3 6.3 0 11.4-5.1 11.4-11.4-.1-2.8-1.2-5.7-3.3-7.8zM12 21.4c-1.7 0-3.3-.5-4.8-1.3l-.4-.2-3.5 1 1-3.4L4 17c-1-1.5-1.4-3.2-1.4-5.1 0-5.2 4.2-9.4 9.4-9.4 2.5 0 4.9 1 6.7 2.8 1.8 1.8 2.8 4.2 2.8 6.7-.1 5.2-4.3 9.4-9.5 9.4zm5.1-7.1c-.3-.1-1.7-.9-1.9-1-.3-.1-.5-.1-.7.1-.2.3-.8 1-.9 1.1-.2.2-.3.2-.6.1s-1.2-.5-2.3-1.4c-.9-.8-1.4-1.7-1.6-2-.2-.3 0-.5.1-.6s.3-.3.4-.5c.2-.1.3-.3.4-.5.1-.2 0-.4 0-.5C10 9 9.3 7.6 9 7c-.1-.4-.4-.3-.5-.3h-.6s-.4.1-.7.3c-.3.3-1 1-1 2.4s1 2.8 1.1 3c.1.2 2 3.1 4.9 4.3.7.3 1.2.5 1.6.6.7.2 1.3.2 1.8.1.6-.1 1.7-.7 1.9-1.3.2-.7.2-1.2.2-1.3-.1-.3-.3-.4-.6-.5z" /></svg>
          </div>Share on WhatsApp</div>
        </a>

      </div>




      <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginTop: '20px', padding: '20px', background: '#333' }}>
        {/* YouTube Button */}
        <a
          href="https://www.youtube.com/channel/UCTirsoDgGvqiWsz9XaJU14g"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: 'red', fontSize: '2rem' }}
          aria-label="Visit our You Turn YouTube channel"
        >
          <FaYoutube />
        </a>

        {/* Facebook Button */}
        <a
          href="https://www.facebook.com/youturnyoutube"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: '#4267B2', fontSize: '2rem' }}
          aria-label="Visit our You Turn Facebook page"
        >
          <FaFacebook />
        </a>

        {/* TikTok Button */}
        <a
          href="https://www.tiktok.com/@youturnyoutube"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: 'black', fontSize: '2rem' }}
          aria-label="Visit our You Turn TikTok profile"
        >
          <FaTiktok />
        </a>

      </div>

    </div>
  );
}

export default App;